import React from "react";
import { StyleSheet ,View , Switch , Image , TextInput , ScrollView , TouchableOpacity , Text , Alert } from "react-native";
import BotonesTienda from "../components/BotonesTienda";
import AsyncStorage from '@react-native-async-storage/async-storage';
import Firebase from "../db/Firebase";
import { getDownloadURL, getStorage, ref, uploadString } from "firebase/storage";
import * as ImagePicker from 'expo-image-picker';
import { doc, setDoc , updateDoc, getDocs , addDoc , collection , getCountFromServer , Timestamp , query , where, Firestore} from "firebase/firestore";
import Preguntas from "./Preguntas";
import Ionicons from '@expo/vector-icons/Ionicons';
import ContadorPreguntas from "../components/ContadorPreguntas";

const styles = StyleSheet.create({
    container:{
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonGrande: {
        padding: 14,
        textAlign: 'center',
        backgroundColor: '#b51f22',
        color: '#ffffff',
        // borderRadius: 30,
        marginTop: 10,
        marginBottom: 10,
        width: '100%',
        maxWidth: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonGrandeOutline: {
        padding: 14,
        textAlign: 'center',
        // backgroundColor: '#ffffff',
        borderColor: '#b51f22',
        borderWidth: 1,
        color: '#b51f22',
        // borderRadius: 30,
        marginTop: 30,
        width: '100%',
        maxWidth: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonGrandeOutlineTexto: {
        color: '#b51f22',
    },
    buttonGrandeTexto: {
        color: '#ffffff',
    },
    input: {
        padding: 14,
        borderStyle: 'solid',
        borderColor: '#b51f22',
        borderWidth: 1,
        // borderRadius: 30,
        marginBottom: 5,
        width: '100%',
        maxWidth: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    containerDetalles: {
        borderStyle: 'solid',
        borderColor: '#b51f22',
        borderWidth: 1,
        // borderRadius: 30,
        marginTop: 15,
        marginBottom: 5,
        width: '100%',
        overflow: 'hidden',
        padding: 10,
        maxWidth: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    titulo: {
        fontWeight: 'bold',
        fontSize: 23.5,
        margin: 20,
        textAlign: 'center',
      },
    logo: {
        width: 150,
        height: 187,
        marginBottom: 20,
        marginRight: 'auto',
        marginLeft: 'auto',
      },
      detalleHome: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        // borderBottomColor: '#b51f22',
        // borderBottomWidth: 1,
        padding: 10,
        marginBottom: 5,
      },
      descripcionItem: {
        fontSize: 16,
      },
      detalleItem:{
        fontSize: 21,
      },
});


let userId = null;
let tipoUser = null;

  async function authFn() {
    userId = await AsyncStorage.getItem('authToken');
    tipoUser = await AsyncStorage.getItem('tipoUser');
  }

  authFn()

async function getProductos()
{
    const coll = collection(Firebase, "productos");
    const q = query(coll, where("userId", "==", userId));
    const snapshot = await getCountFromServer(q);
    const cantidadProductos = snapshot.data().count;

    return cantidadProductos;
}
async function getDataTienda()
{
    const colTienda = collection(Firebase, "tiendas");
    const qTienda = query(colTienda, where("userId", "==", userId));
    const querySnapshot = await getDocs(qTienda);
    const tiendaSnap = querySnapshot.docs[0];

    return tiendaSnap;
    // const tiendaData = tiendaSnap.data();
    // const tiendaId = tiendaSnap.id;
   
}

async function pickImage()
{
    let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
  
  
      if (!result.canceled) {
      
        return result.assets[0].uri
      }

      return null

}

async function guardarDatos(data , tiendaId , image = null , foto1 , foto2 , foto3 , foto4 , foto5 ,navigation)
{
    let nombreImagen;
    let storage;
    let storageRef;

    if(image && image.search('https://') == -1)
    {
        nombreImagen = 'tiendas/perfil-'+userId+'.jpg';
        storage = getStorage();
        storageRef = ref(storage, nombreImagen);
        
        await uploadString(storageRef, image, 'data_url').then(async (snapshot) => {
            await getDownloadURL(storageRef).then((url) => {
                data.image = url;
                
            })
           
        });

        // data.image = nombreImagen;
    }
    
    if(foto1 && foto1.search('https://') == -1)
    {
        nombreImagen = 'tiendas/foto1-'+userId+'.jpg';
        storage = getStorage();
        storageRef = ref(storage, nombreImagen);
        
        await uploadString(storageRef, foto1, 'data_url').then(async (snapshot) => {
            await getDownloadURL(storageRef).then((url) => {
                data.foto1 = url;
                
            })
           
        });

        // data.image = nombreImagen;
    }
    
    if(foto2 && foto2.search('https://') == -1)
    {
        nombreImagen = 'tiendas/foto2-'+userId+'.jpg';
        storage = getStorage();
        storageRef = ref(storage, nombreImagen);
        
        await uploadString(storageRef, foto2, 'data_url').then(async (snapshot) => {
            await getDownloadURL(storageRef).then((url) => {
                data.foto2 = url;
                
            })
           
        });

        // data.image = nombreImagen;
    }
    
    if(foto3 && foto3.search('https://') == -1)
    {
        nombreImagen = 'tiendas/foto3-'+userId+'.jpg';
        storage = getStorage();
        storageRef = ref(storage, nombreImagen);
        
        await uploadString(storageRef, foto3, 'data_url').then(async (snapshot) => {
            await getDownloadURL(storageRef).then((url) => {
                data.foto3 = url;
                
            })
           
        });

        // data.image = nombreImagen;
    }
    
    if(foto4 && foto4.search('https://') == -1)
    {
        nombreImagen = 'tiendas/foto4-'+userId+'.jpg';
        storage = getStorage();
        storageRef = ref(storage, nombreImagen);
        
        await uploadString(storageRef, foto4, 'data_url').then(async (snapshot) => {
            await getDownloadURL(storageRef).then((url) => {
                data.foto4 = url;
                
            })
           
        });

        // data.image = nombreImagen;
    }
    
    if(foto5 && foto5.search('https://') == -1)
    {
        nombreImagen = 'tiendas/foto5-'+userId+'.jpg';
        storage = getStorage();
        storageRef = ref(storage, nombreImagen);
        
        await uploadString(storageRef, foto5, 'data_url').then(async (snapshot) => {
            await getDownloadURL(storageRef).then((url) => {
                data.foto5 = url;
                
            })
           
        });

        // data.image = nombreImagen;
    }
    
   
    await updateDoc(doc(Firebase, "tiendas", tiendaId),
        data,
      );

      navigation.navigate('Notificacion' , {
        type: 'success',
        title: '¡Datos guardados!',
        message: 'Sus datos se actualizaron correctamente.'
      })
}

export default function HomeTienda({navigation}){
    
    const [tiendaId, SetTiendaId] = React.useState('');
    const [nombre, SetNombre] = React.useState('');
    const [disponibleWallet, SetDisponibleWallet] = React.useState(0);
    const [descripcion, SetDescripcion] = React.useState('');
    const [direccion, SetDireccion] = React.useState('');
    const [telefono, SetTelefono] = React.useState('');
    const [datosPago, SetDatosPago] = React.useState('');
    const [tiendaFisica, SetTiendaFisica] = React.useState('');
    const [foto1, SetFoto1] = React.useState('');
    const [foto2, SetFoto2] = React.useState('');
    const [foto3, SetFoto3] = React.useState('');
    const [foto4, SetFoto4] = React.useState('');
    const [foto5, SetFoto5] = React.useState('');
    const [email, SetEmail] = React.useState('');
    const [whatsapp, SetWhatsapp] = React.useState('');
    const [cantidadProductos, SetCantidadProductos] = React.useState(0);
    const [loaded, SetLoaded] = React.useState(0);
    const [image, SetImage] = React.useState(null);
    const [vista , SetVista] = React.useState(1);
    
    function toggleTiendaFisica()
    {
        SetTiendaFisica(tiendaFisica ? 0 : 1);
    }

    if(!loaded){
        authFn().then(() => {
            
                getDataTienda().then((dataTienda) => {
                    let datos = dataTienda.data()
                    
                    SetNombre(datos.name ? datos.name : '')
                    SetDisponibleWallet(datos.disponibleWallet ? datos.disponibleWallet : 0)
                    SetDescripcion(datos.about ? datos.about : '')
                    SetDireccion(datos.direccion ? datos.direccion : '')
                    SetTelefono(datos.telefono ? datos.telefono : '')
                    SetDatosPago(datos.datosPago ? datos.datosPago : '')
                    SetTiendaFisica(datos.tiendaFisica ? datos.tiendaFisica : '')
                    SetFoto1(datos.foto1 ? datos.foto1 : '')
                    SetFoto2(datos.foto2 ? datos.foto2 : '')
                    SetFoto3(datos.foto3 ? datos.foto3 : '')
                    SetFoto4(datos.foto4 ? datos.foto4 : '')
                    SetFoto5(datos.foto5 ? datos.foto5 : '')
                    SetEmail(datos.email ? datos.email : '')
                    SetWhatsapp(datos.whatsapp ? datos.whatsapp : '')
                    SetTiendaId(dataTienda.id)
                    SetImage(datos.image)
                    
                    // if(datos.image && image == null)
                    // {
                    //     getDownloadURL(ref( getStorage() , datos.image)).then((url) => {
                    //         SetImage(url)
                    //     })
                    // }
                    SetLoaded(1)
                })

                getProductos().then((cantidad) => {
                    SetCantidadProductos(cantidad)
                })

            
        })
    }
    

    return (
        <View style={{flex: 1}}>
            {loaded && <>
            {/* Datos */}
            {vista == 1 ? <>
                <ScrollView style={styles.container}>
                    <Text style={styles.titulo}>Inicio</Text>
                    
                    <Image
                    style={styles.logo}
                    source={ require('../img/logo.png')}
                    />

                        <TouchableOpacity style={ { flexDirection: 'row' , justifyContent: 'space-between' , ...styles.buttonGrande} } onPress={() => SetVista(2)}>
                            <Text  style={styles.buttonGrandeTexto}>Preguntas
                            </Text>
                            <ContadorPreguntas tiendaId={tiendaId}/>
                        </TouchableOpacity>

                    {image && <Image source={{ uri: image }} style={{ width: '60%', height: 200, marginRight: 'auto' , marginLeft: 'auto' , marginBottom: 10}} />}
                    <TouchableOpacity style={styles.buttonGrande}>
                        <Text style={styles.buttonGrandeTexto} onPress={() => pickImage().then((image) => {SetImage(image)})}>
                            Seleccionar Imagen
                        </Text>
                    </TouchableOpacity>

                    <TextInput 
                        style={styles.input}
                        placeholder="Nombre"
                        onChangeText={SetNombre}
                        value={nombre}
                    />
                    
                    
                    
                    <TextInput 
                        style={styles.input}
                        multiline
                        numberOfLines={4}
                        placeholder="Descripcion"
                        onChangeText={SetDescripcion}
                        value={descripcion}
                    />
                    
                    <TextInput 
                        style={styles.input}
                        multiline
                        numberOfLines={4}
                        placeholder="Dirección"
                        onChangeText={SetDireccion}
                        value={direccion}
                    />

                    <TextInput 
                        style={styles.input}
                        placeholder="Teléfono"
                        onChangeText={SetTelefono}
                        value={telefono}
                    />
                    
                    <TextInput 
                        style={styles.input}
                        placeholder="Email de contacto"
                        onChangeText={SetEmail}
                        value={email}
                    />
                    
                    <TextInput 
                        style={styles.input}
                        placeholder="Whatsapp"
                        onChangeText={SetWhatsapp}
                        value={whatsapp}
                    />

                    <TextInput 
                        style={styles.input}
                        multiline
                        numberOfLines={4}
                        placeholder="Datos de pago"
                        onChangeText={SetDatosPago}
                        value={datosPago}
                    />
                    
                    <View style={{width: '100%' , maxWidth: 300 , marginTop: 10 , marginBottom: 10, marginLeft: 'auto' , marginRight: 'auto' , flexDirection: 'row' , justifyContent: 'space-between'}}>
                        <Text>Tienda Física</Text>
                        <Switch 
                            trackColor={{false: '#767577', true: '#81b0ff'}}
                            thumbColor={tiendaFisica ? '#f5dd4b' : '#f4f3f4'}
                            ios_backgroundColor="#3e3e3e"
                            onValueChange={toggleTiendaFisica}
                            value={tiendaFisica}
                        />

                    </View>

                    {tiendaFisica ? <>
                        <View style={{width: '100%' , maxWidth: 300 , marginLeft: 'auto' , marginRight: 'auto'}}>
                                
                                <Text style={{textAlign:'center', fontSize: 20 , marginBottom: 18 , fontWeight: 'bold'}}>Fotos de la Tienda</Text>

                                {foto1 && <Image source={{ uri: foto1 }} style={{ width: '100%', height: 200, marginBottom: 10}} />}
                                <TouchableOpacity style={styles.buttonGrande}>
                                    <Text style={styles.buttonGrandeTexto} onPress={() => pickImage().then((foto1) => {SetFoto1(foto1)})}>
                                        Seleccionar Imagen 1
                                    </Text>
                                </TouchableOpacity>
                                
                                {foto2 && <Image source={{ uri: foto2 }} style={{ width: '100%', height: 200, marginBottom: 10}} />}
                                <TouchableOpacity style={styles.buttonGrande}>
                                    <Text style={styles.buttonGrandeTexto} onPress={() => pickImage().then((foto2) => {SetFoto2(foto2)})}>
                                        Seleccionar Imagen 2
                                    </Text>
                                </TouchableOpacity>
                                
                                {foto3 && <Image source={{ uri: foto3 }} style={{ width: '100%', height: 200, marginBottom: 10}} />}
                                <TouchableOpacity style={styles.buttonGrande}>
                                    <Text style={styles.buttonGrandeTexto} onPress={() => pickImage().then((foto3) => {SetFoto3(foto3)})}>
                                        Seleccionar Imagen 3
                                    </Text>
                                </TouchableOpacity>
                                
                                {foto4 && <Image source={{ uri: foto4 }} style={{ width: '100%', height: 200, marginBottom: 10}} />}
                                <TouchableOpacity style={styles.buttonGrande}>
                                    <Text style={styles.buttonGrandeTexto} onPress={() => pickImage().then((foto4) => {SetFoto4(foto4)})}>
                                        Seleccionar Imagen 4
                                    </Text>
                                </TouchableOpacity>
                                
                                {foto5 && <Image source={{ uri: foto5 }} style={{ width: '100%', height: 200, marginBottom: 10}} />}
                                <TouchableOpacity style={styles.buttonGrande}>
                                    <Text style={styles.buttonGrandeTexto} onPress={() => pickImage().then((foto5) => {SetFoto5(foto5)})}>
                                        Seleccionar Imagen 5
                                    </Text>
                                </TouchableOpacity>

                        </View>
                    </> : <></>}

                    <TouchableOpacity style={styles.buttonGrande}>
                        <Text style={styles.buttonGrandeTexto} onPress={() => guardarDatos({
                            name : nombre ,
                            about: descripcion,
                            direccion: direccion,
                            telefono: telefono,
                            datosPago: datosPago,
                            email: email,
                            whatsapp: whatsapp,
                            tiendaFisica: tiendaFisica
                        } , tiendaId , image , foto1 , foto2 , foto3 , foto4 , foto5 , navigation )}>
                            Guardar
                        </Text>
                    </TouchableOpacity>
                    
                    <View style={styles.containerDetalles}>
                        <View style={styles.detalleHome}>
                            <Text style={styles.descripcionItem}>Disponible para Retiro:</Text>
                            <Text style={styles.detalleItem}>${disponibleWallet}</Text>
                        </View>
                        
                        <View style={styles.detalleHome}>
                            <Text style={styles.descripcionItem}>Productos Registrados:</Text>
                            <Text style={styles.detalleItem}>{cantidadProductos}</Text>
                        </View>

                        <View style={styles.detalleHome}>
                            <Text style={styles.descripcionItem}>Ventas Registradas:</Text>
                            <Text style={styles.detalleItem}>0</Text>
                        </View>
                    </View>
                </ScrollView>
            </> : <></>}
            {/* Preguntas */}
            {vista == 2 ? <>
                <ScrollView style={styles.container}>
                    <View style={{flexDirection: 'row' , justifyContent: 'space-between' ,width: '80%' , marginLeft: 'auto' , marginRight: 'auto'}}>
                        <TouchableOpacity onPress={() => SetVista(1)} >
                              <Ionicons style={{ margin: 20}} name="arrow-back" size={25} color="#000000"/>
                        </TouchableOpacity>
                        <Text style={styles.titulo}>Preguntas</Text>
                        <Text style={{ margin: 20}}>
                        &nbsp; 
                        </Text>
                    </View>
                    
                    <Image
                    style={styles.logo}
                    source={ require('../img/logo.png')}
                    />

                    <Preguntas tiendaId={tiendaId} navigation={navigation} />
                </ScrollView>
            </> : <></>}
                
            </>}
            <BotonesTienda navigation={navigation}/>
        </View>
    );
}