
// import {View , Text} from 'react-native';
import SelectView from "./source/views/SelectView";

// console.log('tipo user' + tipoUser);
// const tipoUser = 1;
// const authToken = null;


export default function App() {

  return (
    <SelectView />
  );
    
}



