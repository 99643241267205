import React from "react";
import { View ,Text} from "react-native";
import { getDocs , collection ,  query , where , orderBy} from "firebase/firestore";
import Firebase from "../db/Firebase";


const DATA = [];

export default function ContadorPreguntas({tiendaId })
{
    const [preguntas , SetPreguntas] = React.useState(0);
    const [loaded , SetLoaded] = React.useState(0);


    async function getConsultas(tiendaId)
    {
        const q = query(
                        collection(Firebase, "consultas") , 
                        where('estatus' , '==' , 0) , 
                        where('tiendaId' , '==' , tiendaId) , 
                        orderBy('fechaCreado' , 'desc')
                        );

        const querySnapshot = await getDocs(q);
        
        DATA.length = 0;
        
        querySnapshot.forEach((doc) => {
                DATA.push({
                id: doc.id, ...doc.data(),
                });
        });

        return DATA;
    }


    if(loaded == 0)
    {
        getConsultas(tiendaId).then(() => {
            SetPreguntas(DATA.length);
            SetLoaded(1);
        });
    }

    return (
        <View>
            {loaded ? <>
                <Text style={{color : '#ffffff'}}>{preguntas}</Text>
            </> : <></>}
        </View>
    );
}