import React from "react";
import Ionicons from '@expo/vector-icons/Ionicons';
import { SafeAreaView , 
          StyleSheet , 
          FlatList,
          Text , 
          ScrollView,
          Image,
          View , 
          TouchableOpacity , 
          TextInput} from "react-native";

const styles = StyleSheet.create({
    container :{
        flex:1,
        alignItems: 'center',
        justifyContent:'center'
    },
    box :{
        alignItems: 'center',
        justifyContent:'center',
        width: '100%',
        maxWidth: 500
    },
    titulo :{
        fontSize: 25,
        fontWeight: 'bold',
        marginBottom: 15,
    },
    button: {
        width:"50%",
        textAlign: 'center',
        marginHorizontal: 'auto',
        padding: 15,
        // borderRadius: 15,
        backgroundColor: '#b51f22',
        marginTop: 20,
    },
    buttonText: {
        color: '#ffffff',
        textAlign: 'center',
    }
});

function Notificacion({route , navigation}){
    return (
        <View style={styles.container}>
            <View style={styles.box}>
                { route.params.type == 'success' && <>
                    <Text style={styles.icono}><Ionicons name="checkmark-circle-outline" size={50} color="#008f39"/></Text>
                </> }
                { route.params.type == 'warning' && <>
                    <Text style={styles.icono}><Ionicons name="warning" size={50} color="#ffff00"/></Text>
                </> }
                { route.params.type == 'fail' && <>
                    <Text style={styles.icono}><Ionicons name="close-circle" size={50} color="#ff0000"/></Text>
                </> }
                <Text style={styles.titulo}>{ route.params.title }</Text>
                <Text style={styles.mensaje}>{ route.params.message }</Text>
                <TouchableOpacity style={styles.button} onPress={() => navigation.goBack()}>
                    <Text style={styles.buttonText}>Aceptar</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
}

export default Notificacion;