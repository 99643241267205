import React from "react";
import AsyncStorage from '@react-native-async-storage/async-storage';
import User from '../views/User';
import UserTienda from '../tienda/UserTienda';
import HomeGuest from '../views/HomeGuest';
import HomeAdmin from '../admin/HomeAdmin';
import { View , Text} from "react-native";



let authToken = null;
let tipoUser = null;

async function userFn() {
   authToken = await AsyncStorage.getItem('authToken');
   tipoUser = await AsyncStorage.getItem('tipoUser');
}

userFn();

export default function SelectView() {
    const [reloadView, SetReloadView] = React.useState(false);
    const [vista, SetVista] = React.useState(0);

    const reload = (authTokenRef , tipoUserRef) =>{
        authToken = authTokenRef;
        tipoUser = tipoUserRef;
        // console.log('asd out') 
        
        SetReloadView(reloadView ? false : true)
    }

    if(authToken == null || authToken == '')
    {
        return (
            <HomeGuest reload={reload}/>
        );
    }
  
    if(authToken !== null || authToken != '')
    {

        if(tipoUser == 3 || tipoUser == '3')
        {
            return (
                <HomeAdmin reload={reload} />
            );
        }
        
        if(tipoUser == 2 || tipoUser == '2')
        {
            return (
                <UserTienda reload={reload} />
            );
        }
    }
    
    return (
        <User reload={reload} />
        
    );
}
