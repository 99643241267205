import React from "react";
import { View , TouchableOpacity , Text , StyleSheet } from "react-native";
import Ionicons from '@expo/vector-icons/Ionicons';
import { useRoute } from '@react-navigation/native';

const styles = StyleSheet.create({
    fondo:{
        // backgroundColor: '#ffffff',
    },
    container : {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',     
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#ffffff',
        // borderTopLeftRadius: 30,
        // borderTopRightRadius: 30,
        shadowColor: '#171717',
        shadowOffset: {width: 0, height: -5},
        shadowOpacity: 0.2,
        shadowRadius: 50,
    },
    buttonFix: {
        textAlign: 'center',
        justifyContent: 'center',
        padding: 10,
    },
    buttonFixActivo: {
        textAlign: 'center',
        justifyContent: 'center',
        padding: 10,
        // backgroundColor: '#ffffff',
        // // borderRadius: '50%',
        borderTopStartRadius: 20,
        borderTopEndRadius: 20,
    },
    iconItem: {
        marginLeft : 'auto',
        marginRight : 'auto',
        color: '#808080',
    },
    iconItemActivo: {
        marginLeft : 'auto',
        marginRight : 'auto',
        color: '#b51f22',
    },
    iconTexto: {
        color: '#808080',
    },
    iconTextoActivo: {
        color: '#b51f22',
    },
});

export default function BotonesTienda({navigation }){
   
    const route = useRoute();
    const routeName = route.name;
   
    
    return (
        <View style={styles.fondo} >
          <View style={styles.container} >
              <TouchableOpacity style={ routeName == 'HomeTienda' ? styles.buttonFixActivo : styles.buttonFix} onPress={() => navigation.navigate('HomeTienda')}>
                <Ionicons style={routeName == 'HomeTienda' ? styles.iconItemActivo : styles.iconItem} name="home" size={20} color="#b51f22"></Ionicons>
                <Text style={routeName == 'HomeTienda' ? styles.iconTextoActivo : styles.iconTexto}>Inicio</Text>
              </TouchableOpacity>
              
              <TouchableOpacity style={ routeName == 'ProductosTienda' ? styles.buttonFixActivo : styles.buttonFix} onPress={() => navigation.navigate('ProductosTienda')}>
                <Ionicons style={routeName == 'ProductosTienda' ? styles.iconItemActivo : styles.iconItem} name="cube" size={20} color="#b51f22"></Ionicons>
                <Text style={routeName == 'ProductosTienda' ? styles.iconTextoActivo : styles.iconTexto}>Productos</Text>
              </TouchableOpacity>
              
              <TouchableOpacity style={ routeName == 'VentasTienda' ? styles.buttonFixActivo : styles.buttonFix} onPress={() => navigation.navigate('VentasTienda')}>
                <Ionicons style={routeName == 'VentasTienda' ? styles.iconItemActivo : styles.iconItem} name="cash" size={20} color="#b51f22"></Ionicons>
                <Text style={routeName == 'VentasTienda' ? styles.iconTextoActivo : styles.iconTexto}>Ventas</Text>
              </TouchableOpacity>
              
              <TouchableOpacity style={ routeName == 'PerfilTienda' ? styles.buttonFixActivo : styles.buttonFix} onPress={() => navigation.navigate('PerfilTienda')}>
                <Ionicons style={routeName == 'PerfilTienda' ? styles.iconItemActivo : styles.iconItem} name="person" size={20} color="#b51f22"></Ionicons>
                <Text style={routeName == 'PerfilTienda' ? styles.iconTextoActivo : styles.iconTexto}>Perfil</Text>
              </TouchableOpacity>

          </View>
        </View>
    );
}