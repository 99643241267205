import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBcXIrZ2zAA3WczO0ao0P1uTWYu5KmLwf0",
    authDomain: "joydisenos-93dc0.firebaseapp.com",
    databaseURL: "https://joydisenos-93dc0.firebaseio.com",
    projectId: "joydisenos-93dc0",
    storageBucket: "joydisenos-93dc0.appspot.com",
    messagingSenderId: "110798291174",
    appId: "1:110798291174:web:3412fbebcba30b71a2bb27",
    measurementId: "G-73WVNWJG4B"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = firebase.firestore();

export default db;
