import React from "react";
import { StyleSheet ,View ,FlatList , Image , TextInput , ScrollView , TouchableOpacity , Text  } from "react-native";
// import BotonesTienda from "../components/BotonesTienda";
import Ionicons from '@expo/vector-icons/Ionicons';
import { collection, getDocs , query , where , deleteDoc , doc} from "firebase/firestore";
import BotonesAdmin from "../components/BotonesAdmin";
import Firebase from "../db/Firebase";

const styles = StyleSheet.create({
    container:{
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    itemLista: {
        padding: 4
    },
    buttonGrande: {
        padding: 14,
        textAlign: 'center',
        backgroundColor: '#b51f22',
        color: '#ffffff',
        // borderRadius: 30,
        marginTop: 10,
        width: '100%',
        maxWidth: '300px',
    },
    buttonGrandeOutline: {
        padding: 14,
        textAlign: 'center',
        // backgroundColor: '#ffffff',
        borderColor: '#b51f22',
        borderWidth: 1,
        color: '#b51f22',
        // borderRadius: 30,
        marginTop: 30,
        width: '100%',
        maxWidth: '300px',
    },
    buttonGrandeOutlineTexto: {
        color: '#b51f22',
    },
    buttonGrandeTexto: {
        color: '#ffffff',
    },
    input: {
        padding: 14,
        borderStyle: 'solid',
        borderColor: '#b51f22',
        borderWidth: 1,
        // borderRadius: 30,
        marginBottom: 5,
        width: '100%',
        maxWidth: '300px',
    },
    titulo: {
        fontWeight: 'bold',
        fontSize: 23.5,
        margin: 20,
        textAlign: 'center',
      },
    logo: {
        width: 100,
        height: 187,
        marginBottom: 80,
        marginRight: 'auto',
        marginLeft: 'auto',
      },
      botonLista: {
        flexDirection: 'row' , 
        justifyContent: 'space-between',
        width: '100%',
        padding: 9,
        backgroundColor: '#ffffff',
        borderWidth: 1,
        borderColor: '#b51f22',
        marginBottom: 8,
        
      },
      botonListaTexto: {
        color: '#b51f22',
      },    
      botones: {
        flexDirection: 'row' , 
        justifyContent: 'space-between',
        marginHorizontal: 'auto',
        marginVertical: 25,
        padding: 9,
        backgroundColor: '#ffffff',
        borderWidth: 1,
        borderColor: '#b51f22',
        marginBottom: 8,
        
      },
});


const DATA = [];


async function getUsers(){
    
    const querySnapshot = await getDocs( query(collection(Firebase, "users") , where('tipo' , '==' , 1)) );
    
    DATA.length = 0;

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      DATA.push({
        id: doc.id, ...doc.data(),
      });
   
    });
} 

export default function UsersAdmin({navigation}){
    const [loaded , SetLoaded] = React.useState(0);
    const [editar , SetEditar] = React.useState(0);
    const [usuario , SetUsuario] = React.useState('');
    getUsers().then(() => {
            SetLoaded(1);
        }
    );

    function editarUser(item)
    {
     
        SetUsuario(item)
        SetEditar(1)
        SetLoaded(0)
    }

    function resetForm()
    {
        SetEditar(0)
        SetUsuario('')
        SetLoaded(0)
    }

    async function eliminarRegistro(usuario)
    {
        await deleteDoc(doc(Firebase, "users", usuario.id)
        ).then(() => resetForm());
    }

    return (
        <View style={{flex: 1}}>
            <ScrollView style={styles.container}>
                {editar ? <>
                    <Text style={styles.titulo}>Editar Usuario "{usuario.nombre}"</Text>
                    <TouchableOpacity style={styles.buttonPequeno} onPress={() => {
                        resetForm()
                    }}>
                        <Text style={styles.buttonPequenoTexto} ><Ionicons name="arrow-back" size={20} color="#b51f22"></Ionicons> Volver al listado</Text>
                    </TouchableOpacity>

                    <Image source={{ uri: usuario.image }} style={{ width: '60%', height: 200 , marginRight: 'auto' , marginLeft: 'auto' , marginBottom: 10}} />
                    
                    <Text>Usuario: "{usuario.image}"</Text>
                    <Text>Usuario: "{usuario.nombre}"</Text>
                    <Text>Cédula: "{usuario.cedula}"</Text>
                    <Text>Email: "{usuario.email}"</Text>
                    <Text>Teléfono: "{usuario.telefono}"</Text>

                    <View style={{ flexDirection: 'row' }}>
                        <TouchableOpacity style={styles.botones} onPress={() => eliminarRegistro(usuario)}>
                            <Text style={styles.botonListaTexto} ><Ionicons name="trash" size={15} color="#b51f22"></Ionicons> Eliminar</Text>
                        </TouchableOpacity>
                    </View>
                </> : <>
                    
                    <Text style={styles.titulo}>Usuarios Registradas</Text>

                    {loaded ? <>
                        <FlatList
                            data={DATA}
                            renderItem={({item}) => 
                                <View>
                                    <TouchableOpacity style={styles.botonLista} onPress={() => editarUser(item)} >
                                        <Text style={styles.botonListaTexto}>{item.nombre}</Text>
                                        <Text style={styles.botonListaTexto}>{item.estatus}</Text>
                                    </TouchableOpacity>
                                    
                                </View>
                            }
                            keyExtractor={item => item.id}
                            contentContainerStyle={styles.gridLista}
                            
                            />
                    </> : <>
                        <Text>Cargando Usuarios...</Text>
                    </>}
                
                </>}

                

            </ScrollView>

            <BotonesAdmin navigation={navigation}/>
        </View>
    );
}


