import React from "react";
import { View , TouchableOpacity , FlatList ,Text , StyleSheet  , TextInput} from "react-native";
import { doc, getDoc , getDocs , collection ,  addDoc , Timestamp , query , where , limit , deleteDoc , orderBy} from "firebase/firestore";
import Ionicons from '@expo/vector-icons/Ionicons';
import Firebase from "../db/Firebase";
import db from "../db/Firestore";
import { useRoute } from '@react-navigation/native';

const styles = StyleSheet.create({
    input: {
        padding: 14,
        borderStyle: 'solid',
        borderColor: '#b51f22',
        borderWidth: 1,
        // borderRadius: 30,
        marginBottom: 5,
        width: '100%',
        // maxWidth: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    pregunta: {
        width: '100%',
        color: '#000000',
        padding: 5,
        backgroundColor: '#ffffff',
    },
    respuesta: {
        width: '100%',
        color: '#000000',
        padding: 5,
        paddingLeft: 15,
        backgroundColor: '#ffffff',
    },
    preguntaNombre: {
        width: '100%',
        color: '#000000',
        borderBottomWidth: 1,
        borderBottomColor: '#b51f22',
        padding: 5,
        backgroundColor: '#ffffff',
        textAlign: 'right',
    },
    buttonSend:{
        position: 'absolute',
        top: 0,
        // transform: 'translateY(-50%)',
        right: 0,
        padding: 15,
    }
});

const DATA = [];

export default function Comentarios({productoId , navigation , nombreCliente , tiendaId , userId , productoNombre})
{
    const [comentario , SetComentario] = React.useState({
        consulta: '',
        estatus: 0,
        fechaCreado: null,
        nombre: nombreCliente,
        productoNombre: productoNombre,
        preguntaPadre: null,
        productoId: productoId,
        tiendaId: tiendaId,
        userId: userId
    });
    const [loaded , SetLoaded] = React.useState(0);


    async function getConsultas(productoId)
    {
        const q = query(
                        collection(Firebase, "consultas") , 
                        where('productoId' , '==' , productoId) , 
                        orderBy('fechaCreado' , 'desc'),
                        limit(15),
                        );

        const querySnapshot = await getDocs(q);
        
        DATA.length = 0;
        
        querySnapshot.forEach((doc) => {
                DATA.push({
                id: doc.id, ...doc.data(),
                });
        });

        return DATA;
    }   

    async function enviarConsulta(comentario , navigation)
    {
        if(comentario.consulta == '')
        {
            navigation.navigate('Notificacion' , {
                type: 'warning',
                title: 'Datos Requeridos',
                message: 'en campo consulta es obligatorio.'
            })

            return true
        }
        
        if(comentario.consulta.length < 6)
        {
            navigation.navigate('Notificacion' , {
                type: 'warning',
                title: 'Datos Requeridos',
                message: 'en campo consulta debe tener mínimo 6 caracteres.'
            })

            return true
        }

        await db.collection('consultas')
            .add(comentario).then(() => {
                getConsultas(productoId).then((consultas) => {
                    SetLoaded(0)
                    SetComentario({...comentario , 
                                    consulta : '' , 
                                    fechaCreado : null})
                });
            });
    }


    if(loaded == 0)
    {
        getConsultas(productoId).then(() => {
            SetLoaded(1);
        });
    }

    // console.log(DATA)
    function addConsulta()
    {    
        // console.log(comentario);
        enviarConsulta(comentario , navigation);
    }

    return (
        <View>
            {loaded ? <>
                
                <FlatList
                    data={DATA}
                    keyExtractor={item => item.id}
                    // style={styles.comentarios}
                    renderItem={({item}) => 
                        <>
                            <Text style={styles.pregunta}>
                                {item.consulta}
                            </Text>
                            <Text style={styles.respuesta}>
                                {item.respuesta}
                            </Text>
                            <Text style={styles.preguntaNombre}>{ item.nombre }</Text>
                        </>
                    }
                    />

                <View>
                    <TextInput
                        placeholder="Enviar una pregunta"
                        style={styles.input}
                        onChangeText={val => {
                            SetComentario({
                            ...comentario,
                            fechaCreado: Timestamp.fromDate(new Date()),
                            consulta: val
                            });
                        }}
                    />
                    <TouchableOpacity style={styles.buttonSend} onPress={() => {
                        addConsulta()
                    }}>
                        <Text>
                            <Ionicons name="send" size={15} color='#b51f22'/>
                        </Text>
                    </TouchableOpacity>
                </View>
            </> : <></>}

            
        </View>
    );
}