import React from "react";
import Ionicons from '@expo/vector-icons/Ionicons';
import { SafeAreaView , 
          ImageBackground , 
          StyleSheet , 
          FlatList,
          Text , 
          ScrollView,
          Image,
          View , 
          TouchableOpacity , 
          TextInput} from "react-native";
import Firebase from "../db/Firebase";
import {Picker} from '@react-native-picker/picker';

import { doc, setDoc , getDocs , addDoc , collection , Timestamp , query , or, where, Firestore , orderBy, startAt, endAt , limit} from "firebase/firestore";
import AsyncStorage from '@react-native-async-storage/async-storage';
import BusquedaPrincipal from "../components/BusquedaPrincipal";

// import Botones from "../components/Botones";

const styles = StyleSheet.create({
    container: {
      flex: 1,
      // backgroundColor: '#ececec',
      alignItems: 'center',
      justifyContent: 'center',
    },
    imageFondo: {
      flex: 1,
    },
    inputBuscar: {
        // padding: 14,
        // // borderRadius: 5,
        // marginBottom: 5,
        // marginTop: 15,
        paddingLeft: 10,
        marginLeft: 'auto',
        width: '100%',
    },
    button: {
        // display: 'inline-block',
        flex : 1,
        borderStyle: 'solid',
        borderColor: '#b51f22',
        backgroundColor: '#ffffff',
        borderWidth: 0,
        borderWidth: 2,
        paddingTop: 10,
        paddingRight: 15,
        paddingBottom: 10,
        paddingLeft: 15,
        // borderRadius: 5,
        marginRight: 10,
        // marginLeft: 4,
    },
    buttonBusqueda: {
        // display: 'inline-block',
        flex : 1,
        borderStyle: 'solid',
        borderColor: '#b51f22',
        backgroundColor: '#ffffff',
        borderWidth: 0,
        borderWidth: 2,
        paddingTop: 10,
        paddingRight: 15,
        paddingBottom: 10,
        paddingLeft: 15,
        // borderRadius: 5,
        // marginRight: 10,
        // marginLeft: 4,
    },
    pickerContainer: {
      padding: 5,
      backgroundColor: '#b51f22',
      shadowColor: '#b51f22',
      shadowOffset: {width: 0, height: 0},
      shadowOpacity: 0.8,
      shadowRadius: 15,
    },
    buttonCondicion: {
        // display: 'inline-block',
        // flex : 1,
        borderStyle: 'solid',
        borderColor: '#b51f22',
        backgroundColor: '#ffffff',
        borderWidth: 0,
        borderBottomWidth: 2,
        marginBottom: 10,
        padding: 5,
        textAlign: 'center',
        justifyContent: 'center',

        // paddingTop: 10,
        // paddingRight: 15,
        // paddingBottom: 10,
        // paddingLeft: 15,
        // borderRadius: 5,
        // marginRight: 10,
        // marginLeft: 4,
    },
    buttonFiltro: {
        // display: 'inline-block',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderStyle: 'solid',
        borderColor: '#b51f22',
        backgroundColor: '#ffffff',
        borderWidth: 1,
        // borderRadius: 5,
        // marginLeft: 4,
        width: '80%',
    },
    buttonActivo: {
        // display: 'inline-block',
        flex : 1,
        borderStyle: 'solid',
        borderColor: '#000000',
        backgroundColor: '#ffffff',
        borderWidth: 0,
        borderBottomWidth: 2,
        paddingTop: 10,
        paddingRight: 15,
        paddingBottom: 10,
        paddingLeft: 15,
        // // borderRadius: 5,
        marginRight: 10,
        // marginLeft: 4,
    },
    buttonActivoCondicion: {
        // display: 'inline-block',
        // flex : 1,
        borderStyle: 'solid',
        borderColor: '#000000',
        backgroundColor: '#ffffff',
        borderWidth: 0,
        borderBottomWidth: 2,
        marginBottom: 10,
        padding: 5,
        textAlign: 'center',
        justifyContent: 'center',
        // paddingTop: 10,
        // paddingRight: 15,
        // paddingBottom: 10,
        // paddingLeft: 15,
        // // borderRadius: 5,
        // marginRight: 10,
        // marginLeft: 4,
    },
    buttonText:{
      color: '#b51f22',
      fontSize: 17,
      textAlign: 'center',
    },
    buttonTextActivo:{
      color: '#000000',
      fontSize: 17,
      textAlign: 'center',
    },
    containerSafeArea: {
      flex: 1,
      marginTop: 0,
      // minHeight: '100vh',
    },
    gridLista : {
      width: '80%',
      // flexWrap: 'wrap',
      justifyContent: 'space-between',
    //   flexDirection: 'row',
      marginLeft: 'auto',
      marginRight: 'auto',
    //   flexGrow: 1,
    },
    itemLista : {
    //   width: '200px',
      width:'50%',
      justifyContent: 'space-between',
      marginHorizontal: 5,
      marginTop: 15,
      // // borderRadius: 20,
      overflow: 'hidden',
      // backgroundColor: '#ffffff',
    },
    imagenLista: {
      width: '100%',
      height: 170,
      // borderRadius: 5,
      marginTop: 5,
      marginBottom: 5,
    },
    titulo: {
      fontWeight: 'bold',
      fontSize: 23.5,
      margin: 20,
    },
    tituloLista: {
      fontWeight: 'bold',
    },
    precioLista: {
      fontWeight: 'bold',
      fontSize: 16,
      marginTop: 5,
      color: '#b51f22',
    },
    descripcionLista: {
      marginBottom: 15,
    },
    botonVer: {
      textAlign: 'right',
    },
    botonVerTexto: {
      color: '#b51f22',
      fontWeight: 'bold',
    },
    buttonContainer: {
      // flex: 1,
      // flexDirection: 'row',
      width: '80%',
      marginVertical: 10,
      paddingVertical: 8,
    },
    searchTop: {
      flexDirection: 'row',
      width: '80%',
      justifyContent: 'space-between',
      marginTop: 10,
      // backgroundColor: '#ffffff',
      // borderRadius: 5,
    },
    searchTopInput: {
      flexDirection: 'row',
      backgroundColor: '#ffffff',
      width: '80%',
      borderStyle: 'solid',
      borderColor: '#b51f22',
      borderWidth: 1,
      padding: 5,
      // borderRadius: 5,
      marginTop: 'auto',
      marginBottom: 'auto',

    },
    searchIcon: {
        // marginTop: 15,
        // width: 20,
        padding: 5,
        borderRightColor: '#b51f22',
        borderRightWidth: 1,
        borderStyle: 'solid',
    },
    heartIcon: {
        padding: 10,
        // backgroundColor: '#b51f22',
        color: '#000000',
        // borderRadius: 5,
        marginLeft: 5,
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    filtroBack: {
        // backgroundColor: '#b51f22',
        color: '#000000',
        // borderRadius: 5,
        marginLeft: 5,
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    tituloPrincipal: {
      fontSize: 25,
      color: '#000000',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    tituloTienda : {
      textAlign: 'center',
      fontWeight: 'bold',
      color: '#ffffff',
      fontSize: 15,
      marginBottom: 15,
    },
    textTienda : {
      color: '#ffffff',
    },
    imgTienda : {
      height: 50,
      width: '100%',
    },
    containerTitulo: {
      padding: 20,
      backgroundColor: '#b51f22',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '80%',
      flexDirection: 'row',
      // borderRadius: 20,
      marginTop: 40,
      marginBottom: 10,
    },
    containerDescripcion: {
      // padding: 30,
      width: '80%',
      marginTop: 10,
      marginBottom: 10,
      textAlign: 'justify',
    },
    textCategoria: {
      paddingHorizontal: 30,
      paddingVertical: 60,
      fontSize: 20,
      fontWeight: 'bold',
    },
    buttonCategoria: {
      // backgroundColor: '#b51f22',
      // borderRadius: 30,
      marginBottom: 15,
      fontWeight: 'bold',
      overflow: 'hidden',
      shadowColor: '#b51f22',
      shadowOffset: {width: 0, height: 5},
      shadowOpacity: 0.1,
      shadowRadius: 10,
    },
    input: {
      padding: 5,
      borderStyle: 'solid',
      borderColor: '#b51f22',
      borderWidth: 1,
      // borderRadius: 10,
      // margin: 5,
      marginBottom: 15,
      backgroundColor: '#ffffff',
      // width: '80%',
    },
    inputFilter: {
      padding: 5,
      borderStyle: 'solid',
      borderColor: '#b51f22',
      borderWidth: 1,
      // borderRadius: 10,
      // margin: 5,
      marginBottom: 15,
      backgroundColor: '#ffffff',
      // width: '80%',
    },
    logo: {
      width: 150,
      height: 187,
      marginBottom: 10,
      marginRight: 'auto',
      marginLeft: 'auto',
    },
    filtros: {
      position: 'absolute' , 
      top: 0 , 
      right: 0, 
      width: '70%' , 
      maxWidth: 500, 
      zIndex: 999 , 
      backgroundColor: '#b51f22' , 
      height: '100%',
      shadowColor: '#000000',
      shadowOffset: {width: -20, height: 5},
      shadowOpacity: 0.5,
      shadowRadius: 50,
    },
    filtrosHeader: {
      flexDirection: 'row' , 
      justifyContent: 'space-between' , 
      marginBottom: 15,
      backgroundColor: '#ffffff',
      padding: 20,
      paddingTop: 50,
    },
    filtrosBody:{
      padding: 20,
    },
    filtrosTitulo:{
      textAlign: 'center',
      color: '#000000',
      fontSize: 20.
    },
    filtrosSubTitulo:{
      textAlign: 'center',
      color: '#ffffff',
      fontSize: 11
    }
  });

  function LogoutFn()
  {
      AsyncStorage.setItem('authToken', '');
      AsyncStorage.setItem('tipoUser', '');
      window.location.reload(false);
  }

  let authToken = null;

  async function authFn(){
    authToken = await AsyncStorage.getItem('authToken');
  }

  authFn()

  const DATA = [
    // {
    //   picture: 'https://www.entornoestudiantil.com/wp-content/uploads/2018/03/retail-carrito-compra.jpg',
    //   name: 'Producto1',
    //   about: 'Producto para la venta'
    // },
    // {
    //   picture: 'https://www.entornoestudiantil.com/wp-content/uploads/2018/03/retail-carrito-compra.jpg',
    //   name: 'Producto1',
    //   about: 'You don’t have to use many state variables. State variables can hold objects and arrays just fine, so you can still group related data together. However, unlike this.setState in a class, updating a state variable always replaces it instead of merging it.'
    // },
  ];

  const CATEGORIAS = [];
  const CATEGORIASOPT = [];
  const SUBCATEGORIAS = [];
  const SUBCATEGORIASESPECIFICAS = [];
  const SUBCATEGORIASESPECIFICAS2 = [];

    
  const Item = ({item , navigation}) => (
    <View style={styles.itemLista}>
      <TouchableOpacity onPress={() => navigation.navigate('Producto' , {
        productoId : item.id,
        userId: item.userId,
      })}>
        <Image source={{ uri: item.image }} style={styles.imagenLista}/>
      </TouchableOpacity>
      
      <View style={{ flexDirection: 'col' , justifyContent: 'space-between' , alignItems: 'center' , padding: 10,}}>
        <Text style={styles.tituloLista}>{item.nombre}</Text>
        <Text style={styles.precioLista}>${item.precio}</Text>
      </View>
      {/* <Text style={styles.descripcionLista}>{item.descripcion && item.descripcion.substring(0,100)}...</Text> */}

      {/* <TouchableOpacity style = {styles.botonVer}>
        <Text style = {styles.botonVerTexto}>Ver Productos <Ionicons name="arrow-forward" size={20} color="#b51f22"></Ionicons></Text>
      </TouchableOpacity> */}
    </View>
  );

  async function getProductos(buscar = null , userId = null , categoria = null)
  {
      let q = query(collection(Firebase, "productos") , limit(50));

      // if(buscar != null || buscar != '')
      // {
        // const q = query(collection(Firebase, "productos") , orderBy('nombre') , startAt(buscar) , endAt(buscar+'\uf8ff'));
        // //console.log(buscar)     
      // }
      
      if(categoria != null || categoria != '')
      {
        let q = query(
                  collection(Firebase, "productos") , 
                              where('categoria' , '==' , categoria) ,
                              orderBy('fechaCreado' , 'desc') ,
                              limit(100));
        // //console.log(buscar)     
      }

      const querySnapshot = await getDocs(q);
      
      DATA.length = 0;
      //console.log(categoria);
      
      querySnapshot.forEach((doc) => {
          DATA.push({
              id: doc.id, ...doc.data()

          });
          // //console.log(doc.id, " => ", doc.data());
          //console.log(DATA.length)
      });
      //console.log(DATA);

      return DATA;
  }

  async function getProductosCategoria(categoria = null)
  {
      // let q = query(collection(Firebase, "productos"));

      // if(buscar != null || buscar != '')
      // {
        const q = query(collection(Firebase, "productos") , 
                        or(
                          where('categoria' , '==' , categoria) , 
                          where('subcategoria' , '==' , categoria)
                        ),
                        orderBy('fechaCreado' , 'desc') ,
                        limit(100));
        // //console.log(buscar)     
      // }

      const querySnapshot = await getDocs(q);
      
      DATA.length = 0;
      //console.log(categoria);
      
      querySnapshot.forEach((doc) => {
          DATA.push({
              id: doc.id, ...doc.data()

          });
          // //console.log(doc.id, " => ", doc.data());
          // //console.log('DATA.length')
      });

      return DATA;
  }
  
  async function getCategorias(buscar = null)
  {
      const q = query(collection(Firebase, "categorias") , where('padre' , '==' , null) , orderBy('nombre') );

      const querySnapshot = await getDocs(q);
      
      CATEGORIAS.length = 0;
      CATEGORIASOPT.length = 0;
      
      querySnapshot.forEach((doc) => {
          CATEGORIAS.push({
              id: doc.id, ...doc.data()

          });
          CATEGORIASOPT.push(
            <Picker.Item label={doc.nombre} value={doc.nombre} />
          );
          // //console.log(doc.id, " => ", doc.data());
          //console.log(CATEGORIAS.length)
      });

      return CATEGORIAS;
  }
  
  async function getSubCategorias(idRef)
  {console.log('idRef' + idRef)
      const q = query(collection(Firebase, "categorias") , where('padre' , '==' , idRef) , orderBy('nombre') );

      const querySnapshot = await getDocs(q);
      
      SUBCATEGORIAS.length = 0;
      
      querySnapshot.forEach((doc) => {
          SUBCATEGORIAS.push({
              id: doc.id, 
              ...doc.data()

          });
          // //console.log(doc.id, " => ", doc.data());
          //console.log(SUBCATEGORIAS.length)
      });

      return SUBCATEGORIAS;
  }
  
  async function getSubCategoriasHijos(idRef)
  {
      const q = query(collection(Firebase, "categorias") , where('padre' , '==' , idRef) , orderBy('nombre') );

      const querySnapshot = await getDocs(q);
      
      SUBCATEGORIASESPECIFICAS.length = 0;
      
      querySnapshot.forEach((doc) => {
          SUBCATEGORIASESPECIFICAS.push({
              id: doc.id, 
              ...doc.data()

          });
          // //console.log(doc.id, " => ", doc.data());
          //console.log(SUBCATEGORIAS.length)
      });

      return SUBCATEGORIASESPECIFICAS;
  }
  
  async function getSubCategoriasHijos2(idRef)
  {
      const q = query(collection(Firebase, "categorias") , where('padre' , '==' , idRef) , orderBy('nombre') );

      const querySnapshot = await getDocs(q);
      
      SUBCATEGORIASESPECIFICAS2.length = 0;
      
      querySnapshot.forEach((doc) => {
          SUBCATEGORIASESPECIFICAS2.push({
              id: doc.id, 
              ...doc.data()

          });
          // //console.log(doc.id, " => ", doc.data());
          //console.log(SUBCATEGORIAS.length)
      });

      return SUBCATEGORIASESPECIFICAS2;
  }

  
  
// //console.log(use)
function ProductosLista({route, navigation}){
      const [loaded , SetLoaded] = React.useState(0);
      const [buscar , SetBuscar] = React.useState('');
      const [cat , SetCat] = React.useState('');
      const [catSel , SetCatSel] = React.useState('');
      const [subCat , SetSubCat] = React.useState('');
      const [subCatSel , SetSubCatSel] = React.useState('');
      const [subCatHijo , SetSubCatHijo] = React.useState('');
      const [subCatHijoSel , SetSubCatHijoSel] = React.useState('');
      const [subCatHijo2 , SetSubCatHijo2] = React.useState('');
      const [subCatHijo2Sel , SetSubCatHijo2Sel] = React.useState('');
      const [listado , SetListado] = React.useState(0);

      // filtros
      const [filtros , SetFiltros] = React.useState(0);
      const [precioMinimo , SetPrecioMinimo] = React.useState('');
      const [precioMaximo , SetPrecioMaximo] = React.useState('');
      const [condicion , SetCondicion] = React.useState(1);

      function resetCat()
      {
        SetBuscar('')
        SetFiltros(0)
        SetListado(0)
        SetPrecioMaximo('')
        SetPrecioMinimo('')
        SetCondicion(1)
        SetCat('')
        SetSubCat('')
        SetSubCatHijo('')
      }

      function filtrarItems(buscar)
      {
        let filtrado 
        
        filtrado = DATA.filter((el) => 
        el.nombre.toLowerCase().includes(buscar.toLowerCase()))
        
        DATA.length = 0;
        DATA.push(...filtrado);

        //console.log(filtrado);
        // SetLoaded(0);
      }

      const renderMarcasLista = () => {
        return CATEGORIAS.map((categoria) => {
          return <Picker.Item label={categoria.nombre} value={categoria.nombre + '//' + categoria.id} identificador={categoria.id} key={categoria.id + '-id'}/>
        })
      } 

      const renderModelosLista = () => {
        return SUBCATEGORIAS.map((categoria) => {
          return <Picker.Item label={categoria.nombre} value={categoria.nombre + '//' + categoria.id} identificador={categoria.id} key={categoria.id + '-id-sub'}/>
        })
      }    
      
      const renderCategoriasLista = () => {
        return SUBCATEGORIASESPECIFICAS.map((categoria) => {
          return <Picker.Item label={categoria.nombre} value={categoria.nombre + '//' + categoria.id} identificador={categoria.id} key={categoria.id + '-id-sub'}/>
        })
      }    
      
      const renderSubCategoriasLista = () => {
        return SUBCATEGORIASESPECIFICAS2.map((categoria) => {
          return <Picker.Item label={categoria.nombre} value={categoria.nombre + '//' + categoria.id} identificador={categoria.id} key={categoria.id + '-id-sub-2'}/>
        })
      }    
      
      function filtrarItemsAdv(buscar)
      {
        let filtrado 
        let filtros = 0;
        
        if(precioMinimo && precioMinimo != '')
        {
          filtrado = DATA.filter((el) => 
          parseFloat(el.precio) >= parseFloat(precioMinimo))
          DATA.length = 0;
          DATA.push(...filtrado);
        }
        
        if(precioMaximo && precioMaximo != '')
        {
          filtrado = DATA.filter((el) => 
          parseFloat(el.precio) <= parseFloat(precioMaximo))
          DATA.length = 0;
          DATA.push(...filtrado);
        }
        
        if(condicion && condicion != '')
        {
          filtrado = DATA.filter((el) => 
          el.estadoProducto == condicion)
          DATA.length = 0;
          DATA.push(...filtrado);
        }

        //console.log(filtrado);
        // SetLoaded(0);
      }

      function buscarCat(categoria , idRef)
      {//console.log(idRef)
        if (cat == categoria){
          SetCat(null);
          SetLoaded(0);
        }else{
          SetCat(categoria);
          getSubCategorias(idRef).then(() => {
            SetLoaded(0);

          });
        }
        
      }
      
      function buscarCatSelect(categoria)
      {//console.log(idRef)

        let arrayCat = categoria.split('//');

        if (cat == arrayCat[0]){
          SetCat(null);
          SetCatSel('');
          SetLoaded(0);
        }else{
          SetCat(arrayCat[0]);
          SetCatSel(categoria);
          getSubCategorias(arrayCat[1]).then(() => {
            
            SetLoaded(0);

          });
        }
        
      }
      
      function buscarSubCatSelect(categoria)
      {//console.log(idRef)

        let arrayCat = categoria.split('//');

        if (cat == arrayCat[0]){
          SetSubCat(null);
          SetSubCatSel('');
          SetLoaded(0);
        }else{
          SetSubCat(arrayCat[0]);
          SetSubCatSel(categoria);
          getSubCategoriasHijos(arrayCat[1]).then(() => {
            
            SetLoaded(0);

          });
        }
        
      }
      
      function buscarSubCatHijoSelect(categoria)
      {//console.log(idRef)

        let arrayCat = categoria.split('//');

        if (cat == arrayCat[0]){
          SetSubCatHijo(null);
          SetSubCatHijoSel('');
          SetLoaded(0);
        }else{
          SetSubCatHijo(arrayCat[0]);
          SetSubCatHijoSel(categoria);
          SetLoaded(0);
          getSubCategoriasHijos2(arrayCat[1]).then(() => {
            
            SetLoaded(0);

          });
        }
        
      }
      
      function buscarSubCatHijo2Select(categoria)
      {//console.log(idRef)

        let arrayCat = categoria.split('//');

        if (cat == arrayCat[0]){
          SetSubCatHijo2(null);
          SetSubCatHijo2Sel('');
          SetLoaded(0);
        }else{
          SetSubCatHijo2(arrayCat[0]);
          SetSubCatHijo2Sel(categoria);
          SetLoaded(0);
          // getSubCategoriasHijos(arrayCat[1]).then(() => {
            
          //   SetLoaded(0);

          // });
        }
        
      }

      function buscarFormulario()
      {
        SetCat(catSel.split('//')[0]);
        SetSubCat(subCatSel.split('//')[0]);
        SetSubCatHijo(subCatHijoSel.split('//')[0]);
        SetSubCatHijo2(subCatHijo2Sel.split('//')[0]);
        SetListado(1);
      }

        if(buscar)
        {
          filtrarItems(buscar);
        }

        filtrarItemsAdv()
        if(cat)
        {
          getCategorias();
          getProductosCategoria(cat).then(() => {
            
            SetLoaded(1);
          });
          //console.log('sdfsdf');
        }else{
          getCategorias().then(() => {
              SetLoaded(1);
          });
        }
        
      
    return(  
        <SafeAreaView style={{flex: 1}}>
          <BusquedaPrincipal />
          {filtros ? <>
                <View style={styles.filtros}>

                    <View style={styles.filtrosHeader}>
                      
                      <TouchableOpacity onPress={() => SetFiltros(filtros ? 0 : 1) }>
                            <Ionicons style={styles.filtroBack} name="arrow-back" size={21} color="#ffffff"/>
                      </TouchableOpacity>
                      <Text style={styles.filtrosTitulo}>Buscar</Text>
                    </View>
                    <View style={styles.filtrosBody}>
                      <Text style={styles.filtrosSubTitulo}>Nombre</Text>
                      <TextInput
                            placeholder="Buscar productos"
                            style={styles.inputFilter}
                            onChangeText={SetBuscar}
                            value={buscar}
                            />

                      <Text style={styles.filtrosSubTitulo}>Precio Mínimo</Text>
                      <TextInput
                          style={styles.inputFilter}
                          onChangeText={SetPrecioMinimo}
                          value={precioMinimo}
                          placeholder="Precio Mínimo"
                          // keyboardType="numeric"
                          inputMode="numeric"
                          />
                      
                      <Text style={styles.filtrosSubTitulo}>Precio Máximo</Text>
                      <TextInput
                          style={styles.inputFilter}
                          onChangeText={SetPrecioMaximo}
                          value={precioMaximo}
                          placeholder="Precio Máximo"
                          // keyboardType="numeric"
                          inputMode="numeric"
                        />
                      
                      <View style={{flexDirection:'column', marginLeft: 'auto' , marginRight: 'auto' , width: '100%'}}>
                        <TouchableOpacity style={condicion == 1 ? styles.buttonActivoCondicion : styles.buttonCondicion} onPress={() => { SetCondicion(1) }}>
                          <Text style={condicion == 1 ? styles.buttonTextActivo :  styles.buttonText}>Nuevo</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={condicion == 2 ? styles.buttonActivoCondicion : styles.buttonCondicion} onPress={() => { SetCondicion(2) }}>
                          <Text style={condicion == 2 ? styles.buttonTextActivo :  styles.buttonText}>Usado</Text>
                        </TouchableOpacity>
                      </View>

                      <Text style={styles.filtrosSubTitulo}>Categorías</Text>
                      

                      <View>
                        <FlatList
                            data={CATEGORIAS}
                            keyExtractor={item => item.id}
                            // style={}
                            pagingEnabled
                            horizontal={true}
                            renderItem={({item}) => 
                              <TouchableOpacity 
                                    style={cat == item.nombre ? styles.buttonActivo : styles.button}
                                    onPress={() => 
                                      buscarCat(item.nombre , item.id)
                                      }>
                                      <Text style={cat == item.nombre ? styles.buttonTextActivo :  styles.buttonText}>{item.nombre}</Text>
                              </TouchableOpacity>
                            }
                          />
                      </View>
                      
                      <Text style={styles.filtrosSubTitulo}>Subcategorías</Text>
                      <View>
                        <FlatList
                            data={SUBCATEGORIAS}
                            keyExtractor={item => item.id}
                            // style={}
                            pagingEnabled
                            horizontal={true}
                            renderItem={({item}) => 
                              <TouchableOpacity 
                                    style={cat == item.nombre ? styles.buttonActivo : styles.button}
                                    onPress={() => 
                                      buscarCat(item.nombre , item.id)
                                      }>
                                      <Text style={cat == item.nombre ? styles.buttonTextActivo :  styles.buttonText}>{item.nombre}</Text>
                              </TouchableOpacity>
                            }
                          />
                      </View>

                    </View>
                </View>
          </> : <></>}
          <ImageBackground source={require('../img/fondo.jpg')} resizeMode="cover" style={styles.imageFondo}>
            <ScrollView style={styles.containerSafeArea}>
              <View style={styles.container}>
                
                <View style={styles.searchTop}>
                  
                  {/* <View style={styles.searchTopInput}>
                    <Ionicons style={styles.searchIcon} name="search" size={20} color="#b51f22"/>
                    <TextInput
                      placeholder="Buscar productos o tiendas"
                      style={styles.inputBuscar}
                      onChangeText={SetBuscar}
                      value={buscar}
                    />
                  </View> */}
                  
                    {listado ? <>
                      <View>
                        <TouchableOpacity onPress={() => resetCat()}>
                            <Ionicons style={styles.heartIcon} name="arrow-back" size={21} color="#ffffff"/>
                        </TouchableOpacity>
                      </View>
                      <View>
                        <Text style={styles.tituloPrincipal}>{cat}</Text>
                      </View>
                    </> : <>
                      <View>
                        
                      </View>
                      <View>
                        <Text style={styles.tituloPrincipal}>Categorías</Text>
                      </View>
                    </>}

                  
                      <TouchableOpacity onPress={() => navigation.navigate('LoginForm')}>
                          <Ionicons style={styles.heartIcon} name="log-in" size={21} color="#ffffff"/>
                      </TouchableOpacity>
                  
                  
                </View>

                {/* <Image
                            style={styles.logo}
                            source={ require('../img/logo.png')}
                            /> */}

                  {loaded ? <>

                    {listado ? <>

                      <View style={styles.searchTopInput}>
                        <Ionicons style={styles.searchIcon} name="search" size={20} color="#b51f22"/>
                        <TextInput
                          placeholder="Buscar productos"
                          style={styles.inputBuscar}
                          onChangeText={SetBuscar}
                          value={buscar}
                        />
                      </View>
                      
                      <FlatList
                        data={SUBCATEGORIAS}
                        keyExtractor={item => item.id}
                        style={styles.buttonContainer}
                        horizontal={true}
                        renderItem={({item}) => 
                          <TouchableOpacity 
                                style={cat == item.nombre ? styles.buttonActivo : styles.button}
                                onPress={() => 
                                  buscarCat(item.nombre , item.id)
                                  }>
                                  <Text style={cat == item.nombre ? styles.buttonTextActivo :  styles.buttonText}>{item.nombre}</Text>
                          </TouchableOpacity>
                        }
                      />

                      <TouchableOpacity style={styles.buttonFiltro} onPress={() => SetFiltros(filtros ? 0 : 1) }>
                        <Ionicons style={{padding: 10}} name="funnel" size={20} color="#b51f22"/>
                        <Text style={styles.botonText}>
                          Filtros
                        </Text>
                      </TouchableOpacity>

                      <FlatList
                      data={DATA}
                      renderItem={({item}) => <Item item={item} navigation={navigation}/>}
                      keyExtractor={item => item.id}
                      contentContainerStyle={styles.gridLista}
                      style={{width:'100%'}}
                      numColumns={2}
                    />
                  </> : <>

                  <Image
                            style={styles.logo}
                            source={ require('../img/logo.png')}
                            />
                  <View style={styles.pickerContainer}>
                    <Picker
                      style={styles.input}
                      selectedValue={catSel}
                      onValueChange={(itemValue, itemIndex , identificador) =>
                        buscarCatSelect(itemValue)
                      }>
                      <Picker.Item label="Seleccionar Marca" value="" identificador="" key="id-marca-1" />
                      {renderMarcasLista()}
                    </Picker>
                    
                    <Picker
                      style={styles.input}
                      selectedValue={subCatSel}
                      onValueChange={(itemValue, itemIndex , itemIdentificador) =>
                        buscarSubCatSelect(itemValue)
                      }>
                      <Picker.Item label="Seleccionar Modelo" value="" identificador="" key="id-modelo-2" />
                      {renderModelosLista()}
                    </Picker>
                    
                    <Picker
                      style={styles.input}
                      selectedValue={subCatHijoSel}
                      onValueChange={(itemValue, itemIndex , itemIdentificador) =>
                        buscarSubCatHijoSelect(itemValue)
                      }>
                      <Picker.Item label="Seleccionar Categoria" value="" identificador="" key="id-modelo-3" />
                      {renderCategoriasLista()}
                    </Picker>
                    
                    <Picker
                      style={styles.input}
                      selectedValue={subCatHijo2Sel}
                      onValueChange={(itemValue, itemIndex , itemIdentificador) =>
                        buscarSubCatHijo2Select(itemValue)
                      }>
                      <Picker.Item label="Seleccionar Sub Categoria" value="" identificador="" key="id-modelo-3" />
                      {renderSubCategoriasLista()}
                    </Picker>

                    <TouchableOpacity style={styles.buttonBusqueda} onPress={() => buscarFormulario()}>
                      <Text style={styles.buttonText}>Buscar</Text>
                    </TouchableOpacity>
                  </View>
                      {/* <FlatList
                        data={CATEGORIAS}
                        keyExtractor={item => item.id}
                        style={styles.buttonContainer}
                        renderItem={({item}) => 
                          <TouchableOpacity 
                                style={styles.buttonCategoria}
                                onPress={() => 
                                  buscarCat(item.nombre , item.id)
                                  }>
                                    <ImageBackground source={{uri:  item.image}} resizeMode="cover" style={styles.imageFondo}>
                                      <Text style={styles.textCategoria}>{item.nombre}</Text>
                                    </ImageBackground>
                          </TouchableOpacity>
                        }
                      /> */}
                        
                  </>}
                  
                  </> : <>
                        <Text>Cargando...</Text>
                  </>}
                  
              
                
              </View>
            </ScrollView>
            
          </ImageBackground>

        </SafeAreaView>
      );
}

export default ProductosLista;