import React from "react";
import { View , TouchableOpacity , Text , TextInput , StyleSheet } from "react-native";
import Ionicons from '@expo/vector-icons/Ionicons';

const styles = StyleSheet.create({
    busquedaContainer: {
        backgroundColor: '#b51f22',
        paddingTop: 50,
        width: '100%',
      },
      busquedaPrincipal: {
        padding: 5,
        backgroundColor: '#ffffff',
        flexDirection: 'row',
        width: '90%',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginBottom: 10,
        borderRadius: 20,
      },
      inputBuscar: {
        width: '100%',
        marginLeft: 'auto',
      },
      searchIcon:{
        paddingLeft: 5,
        paddingRight: 5,
      },
      resultados:{
        backgroundColor: '#ffffff',
        width: '90%',
        height: '80vh',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginBottom: 20,
        padding: 10,
        borderRadius: 20,
      }
})

export default function BusquedaPrincipal()
{
    const [buscar , SetBuscar] = React.useState('');

    return (
        <View style={styles.busquedaContainer}>
            <View style={styles.busquedaPrincipal}>
                <Ionicons style={styles.searchIcon} name="search" size={20} color="#b51f22"/>
                <TextInput
                placeholder="Buscar productos"
                style={styles.inputBuscar}
                onChangeText={SetBuscar}
                value={buscar}
                />
            </View>

            {buscar ? <>
                
                <View style={styles.resultados}>
                    <TouchableOpacity style={styles.resultadoItem}>
                        <Text>
                            Resultado
                        </Text>
                    </TouchableOpacity>
                </View>
                
            </> : <></>}
          </View>
    );
}