import React from "react";
import { ImageBackground , StyleSheet ,View , Image , TextInput , ScrollView , TouchableOpacity , Text } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as ImagePicker from 'expo-image-picker';
import { doc, getDoc , updateDoc} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadString } from "firebase/storage";
import Firebase from "../db/Firebase";
import Botones from "../components/Botones";

const styles = StyleSheet.create({
    container:{
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    imageFondo: {
        flex: 1,
      },
      containerSafeArea: {
        flex: 1,
        marginTop: 0,
        // minHeight: '100vh',
      },
    buttonGrande: {
        padding: 14,
        textAlign: 'center',
        backgroundColor: '#b51f22',
        color: '#ffffff',
        // borderRadius: 30,
        marginTop: 10,
        marginBottom: 10,
        width: '100%',
        maxWidth: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonGrandeOutline: {
        padding: 14,
        textAlign: 'center',
        backgroundColor: '#ffffff',
        borderColor: '#b51f22',
        borderWidth: 1,
        color: '#b51f22',
        // borderRadius: 30,
        marginTop: 30,
        width: '100%',
        maxWidth: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonGrandeOutlineTexto: {
        color: '#b51f22',
    },
    buttonGrandeTexto: {
        color: '#ffffff',
    },
    input: {
        padding: 14,
        borderStyle: 'solid',
        borderColor: '#b51f22',
        backgroundColor: '#ffffff',
        borderWidth: 1,
        // borderRadius: 30,
        marginBottom: 5,
        width: '100%',
        maxWidth: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    titulo: {
        fontWeight: 'bold',
        fontSize: 23.5,
        margin: 20,
        textAlign: 'center',
      },
    logo: {
        width: 150,
        height: 187,
        marginBottom: 20,
        marginRight: 'auto',
        marginLeft: 'auto',
      },
      imgStyle : { 
        width: '60%', 
        height: 200 , 
        // borderRadius: 30, 
        marginRight: 'auto' , 
        marginLeft: 'auto' , 
        marginBottom: 10
    }
});

function LogoutFn(reload)
{
    AsyncStorage.setItem('authToken', '');
    AsyncStorage.setItem('tipoUser', '');
    // window.location.reload(false);
    reload('' , '' , '')
}

let authToken = null;
let docRef = null;
let docSnap = null;
let userRef = null;

async function authFn(){
    authToken = await AsyncStorage.getItem('authToken');

    docRef = authToken == '' || authToken == null  
        ? null 
        : doc(Firebase , 'users' , authToken);
    docSnap = authToken == '' || authToken == null  
        ? null 
        : await getDoc(docRef);
    userRef = authToken == '' || authToken == null  
        ? {} 
        : docSnap.data();

    return authToken;
}




async function guardarDatos(data , image = null , navigation)
{
    if(image && image.search('https://') == -1)
    {
        const nombreImagen = 'perfiles/perfil-'+authToken+'.jpg';
        const storage = getStorage();
        const storageRef = ref(storage, nombreImagen);
        
        await uploadString(storageRef, image, 'data_url').then( async(snapshot) => {
            //console.log(snapshot.metadata.bucket + '/' + snapshot.metadata.fullPath);
            await getDownloadURL(storageRef).then((url) => {
                data.image = url;
                // //console.log(data);
            })
        });

        // data.image = nombreImagen;
    }

    await updateDoc(doc(Firebase, "users", authToken),
        data,
    );

    navigation.navigate('Notificacion' , {
        type: 'success',
        title: '¡Perfil actualizado!',
        message: 'Tus datos se han actualizado correctamente.'
    })

}

async function pickImage()
{
    let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
  
      //console.log(result);
  
      if (!result.canceled) {
        //console.log(result.assets[0].uri);
        return result.assets[0].uri
      }

      return null

}

authFn()

export default function Perfil({route , navigation}){
    const [loaded, SetLoaded] = React.useState(0);
    const [nombre, SetNombre] = React.useState(userRef.nombre);
    const [email, SetEmail] = React.useState(userRef.email);
    const [telefono, SetTelefono] = React.useState(userRef.telefono ? userRef.telefono : '');
    const [cedula, SetCedula] = React.useState(userRef.cedula ? userRef.cedula : '');
    const [image, setImage] = React.useState(userRef.image ? userRef.image : '');

    if(!loaded)
    {
        authFn().then((val) => {
            if(val && val != '')
            {
                SetNombre(userRef.nombre)
                SetEmail(userRef.email)
                SetTelefono(userRef.telefono ? userRef.telefono : '')
                SetCedula(userRef.cedula ? userRef.cedula : '')
                setImage(userRef.image ? userRef.image : '')
                SetLoaded(1)
            }
        })
    }
    
    
    return (
        <View style={{flex: 1}}>
            <ImageBackground source={require('../img/fondo.jpg')} resizeMode="cover" style={styles.imageFondo}>
                <ScrollView style={styles.containerSafeArea}>
                
                    {loaded && <>
                    
                        <View style={styles.container} >
                            <Text style={styles.titulo}>Perfil</Text>

                            <Image
                            style={styles.logo}
                            source={ require('../img/logo.png')}
                            />

                            {image ? <Image 
                                source={{ uri: image }} 
                                style={styles.imgStyle} 
                            /> : <></>}

                            <TouchableOpacity style={styles.buttonGrande}>
                                <Text style={styles.buttonGrandeTexto} onPress={() => pickImage().then((image) => {setImage(image)})}>
                                    Seleccionar Imagen
                                </Text>
                            </TouchableOpacity>

                            <TextInput 
                                style={styles.input}
                                placeholder="Nombre"
                                onChangeText={SetNombre}
                                value={nombre}
                            />
                            
                            <TextInput 
                                style={styles.input}
                                placeholder="Cédula"
                                onChangeText={SetCedula}
                                value={cedula}
                            />
                            
                            <TextInput 
                                style={styles.input}
                                placeholder="Email"
                                onChangeText={SetEmail}
                                value={email}
                            />
                            
                            <TextInput 
                                style={styles.input}
                                placeholder="Teléfono"
                                onChangeText={SetTelefono}
                                value={telefono}
                            />

                            <TouchableOpacity style={styles.buttonGrande}>
                                <Text style={styles.buttonGrandeTexto} onPress={() => {guardarDatos({   
                                                        nombre: nombre,
                                                        email: email,
                                                        telefono: telefono,
                                                        cedula: cedula,
                                                    } , image , navigation)}}>
                                    Guardar
                                </Text>
                            </TouchableOpacity>
                            
                            <TouchableOpacity style={styles.buttonGrandeOutline}>
                                <Text style={styles.buttonGrandeOutlineTexto} onPress={() => LogoutFn(route.params.reload)}>
                                    Cerrar Sesión
                                </Text>
                            </TouchableOpacity>
                        </View>
                    
                    </>}
                </ScrollView>

                <Botones navigation={navigation}/>
            </ImageBackground>

            
        </View>
    );
}