import React from "react";
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Producto from "./Producto";
import ProductosLista from "./ProductosLista";
import LoginForm from "./LoginForm";
import Notificacion from "./Notificacion";


const Stack = createNativeStackNavigator();

export default function HomeGuest({navigation , reload}){
    
    return(
        <NavigationContainer>
            <Stack.Navigator>
                <Stack.Screen name="ProductosLista" component={ProductosLista}  options={{headerShown: false}} />
                <Stack.Screen name="Producto" initialParams={{ reload: reload }} component={Producto}  options={{headerShown: false}} />
                <Stack.Screen name="LoginForm" component={LoginForm}   initialParams={{ reload: reload }}
                 options={{headerShown: false}} />
                <Stack.Screen name="Notificacion" component={Notificacion}  options={{headerShown: false}} />
            </Stack.Navigator>
        </NavigationContainer>
    );
}