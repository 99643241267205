import React from "react";
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeTienda from "./HomeTienda";
import PerfilTienda from "./PerfilTienda";
import ProductosTienda from "./ProductosTienda";
import VentasTienda from "./VentasTienda";
import Notificacion from "../views/Notificacion";

const Stack = createNativeStackNavigator();

export default function UserTienda({navigation , reload}){
    return(
        <NavigationContainer>
            <Stack.Navigator>
                <Stack.Screen name="HomeTienda" component={HomeTienda}  options={{headerShown: false}} />
                <Stack.Screen name="PerfilTienda" initialParams={{ reload: reload }} component={PerfilTienda}  options={{headerShown: false}} />
                <Stack.Screen name="ProductosTienda" component={ProductosTienda}  options={{headerShown: false}} />
                <Stack.Screen name="VentasTienda" component={VentasTienda}  options={{headerShown: false}} />
                <Stack.Screen name="Notificacion" component={Notificacion}  options={{headerShown: false}} />
            </Stack.Navigator>
        </NavigationContainer>
    );
}