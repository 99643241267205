import React from "react";
import Ionicons from '@expo/vector-icons/Ionicons';
import { SafeAreaView , 
          StyleSheet , 
          ImageBackground,
          Text , 
          ScrollView,
          Linking,
          Image,
          Dimensions,
          View , 
          Alert,
          TouchableOpacity , 
          FlatList,
          TextInput} from "react-native";

import Firebase from "../db/Firebase"
import { doc, getDoc , getDocs , collection ,  addDoc , Timestamp , query , where , limit , deleteDoc , orderBy} from "firebase/firestore";
import Botones from "../components/Botones";
import AsyncStorage from '@react-native-async-storage/async-storage';
import BusquedaPrincipal from "../components/BusquedaPrincipal";
import Comentarios from "../components/Comentarios";


  const styles = StyleSheet.create({

    wrapper: {
    },
    slide1: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#9DD6EB',
    },
    slide2: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#97CAE5',
    },
    slide3: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#92BBD9',
    },
    text: {
      color: '#fff',
      fontSize: 30,
      fontWeight: 'bold',
    },

    container: {
      flex: 1,
      // backgroundColor: '#ececec',
      alignItems: 'center',
      justifyContent: 'center',
    },
    imageFondo: {
      flex: 1,
    },
    imagenProducto: {
      height: 300,
      width: '100%',
      // // borderRadius: 15,
    },
    inputBuscar: {
        // padding: 14,
        // // borderRadius: 5,
        // marginBottom: 5,
        // marginTop: 15,
        paddingLeft: 10,
        marginLeft: 'auto',
        width: '90%',
    },
    input: {
      padding: 14,
      borderStyle: 'solid',
      borderColor: '#b51f22',
      borderWidth: 1,
      // borderRadius: 30,
      marginBottom: 5,
      width: '100%',
      // maxWidth: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
  },
      button: {
        // display: 'inline-block',
        flex : 1,
        borderStyle: 'solid',
        borderColor: '#8a8a8a',
        backgroundColor: '#ffffff',
        borderWidth: 1,
        paddingTop: 10,
        paddingRight: 15,
        paddingBottom: 10,
        paddingLeft: 15,
        // borderRadius: 30,
        marginRight: 10,
        // marginLeft: 4,
    },
    buttonActivo: {
        // display: 'inline-block',
        flex : 1,
        borderStyle: 'solid',
        borderColor: '#8a8a8a',
        backgroundColor: '#b51f22',
        borderWidth: 1,
        paddingTop: 10,
        paddingRight: 15,
        paddingBottom: 10,
        paddingLeft: 15,
        // borderRadius: 30,
        marginRight: 10,
        // marginLeft: 4,
    },
    buttonText:{
      color: '#8a8a8a',
      fontSize: 11,
      textAlign: 'center',
    },
    buttonTextActivo:{
      color: '#ffffff',
      fontSize: 11,
      textAlign: 'center',
    },
    containerSafeArea: {
      flex: 1,
      marginTop: 0,
    },
    gridLista : {
      width: '100%',
      // flexWrap: 'wrap',
      justifyContent: 'space-between',
    //   flexDirection: 'row',
      marginLeft: 'auto',
      marginRight: 'auto',
    //   flexGrow: 1,
    },
    itemLista : {
      //   width: '200px',
        width:'50%',
        justifyContent: 'space-between',
        paddingHorizontal: 5,
        marginTop: 10,
      },
      subtituloText:{
        color: '#b51f22',
        marginBottom: 5
      },
    imagenLista: {
      width: '100%',
      height: 170,
      // // borderRadius: 15,
      marginTop: 5,
      marginBottom: 5,
    },
    titulo: {
      fontWeight: 'bold',
      fontSize: 22,
      margin: 20,
      textAlign: 'center',
    },
    tituloLista: {
      fontWeight: 'bold',
    },
    descripcionLista: {
      marginBottom: 15,
    },
    botonVer: {
      textAlign: 'right',
    },
    botonVerTexto: {
      color: '#b51f22',
      fontWeight: 'bold',
    },
    buttonContainer: {
      // flex: 1,
      // flexDirection: 'row',
      width: '80%',
    },
    searchTop: {
      flexDirection: 'row',
      width: '80%',
      justifyContent: 'space-between',
      marginTop: 10,
      marginHorizontal: 'auto',
      // backgroundColor: '#ffffff',
      // borderRadius: 5,
    },
    searchTopInput: {
      flexDirection: 'row',
      width: '90%',
      borderStyle: 'solid',
      borderColor: '#b51f22',
      borderWidth: 1,
      padding: 5,
      // borderRadius: 5,
      marginTop: 'auto',
      marginBottom: 'auto',

    },
    searchIcon: {
        // marginTop: 15,
        // width: 20,
        padding: 5,
        borderRightColor: '#b51f22',
        borderRightWidth: 1,
        borderStyle: 'solid',
    },
    heartIcon: {
        padding: 10,
        backgroundColor: '#b51f22',
        // borderRadius: 5,
        marginLeft: 5,
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    arrowIcon: {
        padding: 10,
        // backgroundColor: '#b51f22',
        // borderRadius: 5,
        marginLeft: 5,
    },
    botonCompra: {
      flexDirection :'row', 
      justifyContent: 'center',
      alignItems:'center' ,
      backgroundColor: '#b51f22',
      // width: '65%',
      width: '100%',
      padding: 5,
      marginTop: 10,
      marginBottom: 10,
      // borderRadius: 15,
    },
    botonComentar: {
      flexDirection :'row', 
      justifyContent: 'center',
      alignItems:'center' ,
      backgroundColor: '#ffffff',
      borderStyle: 'solid',
      borderColor: '#b51f22',
      borderWidth: 1,
      // width: '65%',
      width: '100%',
      padding: 5,
      marginTop: 10,
      marginBottom: 10,
      // borderRadius: 15,
    },
    botonAgregarFav: {
      flexDirection :'row', 
      justifyContent: 'center',
      alignItems:'center' ,
      backgroundColor: '#b51f22',
      width: '48%',
      padding: 5,
      marginTop: 10,
      marginBottom: 10,
      // borderRadius: 15,
    },
    botonLogin: {
      flexDirection :'row', 
      justifyContent: 'center',
      alignItems:'center' ,
      backgroundColor: '#b51f22',
      width: '100%',
      padding: 5,
      marginTop: 10,
      marginBottom: 10,
      // borderRadius: 15,
    },
    botonVerProductos: {
      flexDirection :'row', 
      justifyContent: 'center',
      alignItems:'center' ,
      backgroundColor: '#5A5A5A',
      width: '100%',
      padding: 10,
      marginTop: 10,
      marginBottom: 10,
      // borderRadius: 15,
    },
    botonAddCarrito: {
      flexDirection :'row', 
      justifyContent: 'center',
      alignItems:'center' ,
      backgroundColor: '#b51f22',
      width: '100%',
      padding: 5,
      marginTop: 10,
      marginBottom: 10,
      // borderRadius: 15,
    },
    botonCompraText: {
      color: "#ffffff",
    },
    botonComentarText: {
      color: "#b51f22",
    },
    comentario:{
      width: '100%',
      backgroundColor: '#ffffff',
      borderWidth: 1,
      borderColor: '#b51f22',
      borderStyle: 'solid',
      padding: 15,
      // borderRadius: 15,
      marginRight : 10,
    },
    comentarioBox:{
      paddingRight: 10,
      paddingBottom: 10,
      width: '100%',
    },
    puntosBox:{
      flexDirection: 'row',
      justifyContent: "space-around",
      width: '100%',
      marginBottom: 5,
    },
    fotosContainer:{
      position: 'relative',
    },
    topNavContainer: {
      position: 'absolute',
      top: 0 ,
      left: 0,
      zIndex: 999,
      width: '10%',
    }
  });

  let TiendaData = null;
  const RELACIONADOS = [];
  const COMENTARIOS = [];
  const FOTOSTIENDA = [];
  const COMENTARIOSPRODUCTO = [];
  const IMAGENES = [];
  const WIDTHSCREEN = Dimensions.get('window').width;
  
  let authToken = null;
  let docRef = null;
  let docSnap = null;
  let userRef = null;

  async function authFn() {
    authToken = await AsyncStorage.getItem('authToken');

    docRef = authToken == '' || authToken == null  
      ? null 
      : doc(Firebase , 'users' , authToken);
    docSnap = authToken == '' || authToken == null  
        ? null 
        : await getDoc(docRef);
    userRef = authToken == '' || authToken == null  
        ? {} 
        : docSnap.data();
  }

  authFn()

  function estadoProducto(estado)
  {
    switch (estado) {
      case 1:
        return 'Nuevo';
        break;
      case 2:
        return 'Usado';
        break;
    
      default:
        return 'No definido';
        break;
    }
  }
  
  // const carrito = await AsyncStorage.getItem('carritoActual');

  async function agregarFavorito(producto , productoId , navigation)
  {
    //console.log(authToken)
    //console.log(productoId)
      const favoritoDoc = collection(Firebase , 'favoritos')

      const favs = query(favoritoDoc , where('userId' , '==' , authToken) , where('productoId' , '==' , productoId))

      const favsConsulta = await getDocs(favs)

      favsConsulta.forEach(async (docConsulta) => {
        await deleteDoc(doc(Firebase , "favoritos" , docConsulta.id))
          // await deleteDoc()
          // //console.log(doc.id, " => ", doc.data());
      });

      let data = {
        userId : authToken,
        tiendaId : producto.userId,
        productoId : productoId,
        descripcion : producto.descripcion,
        image : producto.image,
        nombre : producto.nombre,
        precio : producto.precio,
      } 

      await addDoc(favoritoDoc, data);

      navigation.navigate('Notificacion' , {
          type: 'success',
          title: '¡Producto agregado!',
          message: 'Se agregó exitosamente a favoritos.'
      })
  }

  async function getProducto(productoId)
  {
    const docRef = doc(Firebase, "productos", productoId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else{
      return null;
      //console.log("No existe!");
    }
  }

  async function getRelacionados(userId = null)
  {
      let q = query(collection(Firebase, "productos") , where('userId' , '==' , userId));

      const querySnapshot = await getDocs(q);
      
      RELACIONADOS.length = 0;
      
      querySnapshot.forEach((doc) => {
          RELACIONADOS.push({
              id: doc.id, ...doc.data()

          });
          
      });
      
      return true;
  }
  
  async function getComentarios(tiendaId = null)
  {
      let q = query(collection(Firebase, "evaluacion") , where('tiendaId' , '==' , tiendaId) , orderBy('fechaCreado' , 'desc') );

      const querySnapshot = await getDocs(q);
      
      COMENTARIOS.length = 0;
      
      querySnapshot.forEach((doc) => {
          COMENTARIOS.push({
              id: doc.id, ...doc.data()

          });
          
      });
      
      return true;
  }
  
  async function getComentariosProducto(productoId = null)
  {
      let q = query(collection(Firebase, "reviews") , 
                      where('productoId' , '==' , productoId) , 
                      orderBy('fechaCreado' , 'desc') 
                    );

      const querySnapshot = await getDocs(q);
      
      COMENTARIOSPRODUCTO.length = 0;
      
      querySnapshot.forEach((doc) => {
        COMENTARIOSPRODUCTO.push({
              id: doc.id, ...doc.data()

          });
          
      });
      
      return true;
  }
  
  async function getTienda(userId)
  {
    const docRef = query(collection(Firebase, "tiendas") , where('userId' , '==' , userId) , limit(1));

    const querySnapshot = await getDocs(docRef);
    
    querySnapshot.forEach((doc) => {
        // //console.log({id : doc.id, ...doc.data()});
          TiendaData = {
              id: doc.id, ...doc.data()
          };

          return true;
      });

    
  }
  
  async function setCarrito(producto)
  {
    const carrito = await AsyncStorage.getItem('carritoActual');

    let carritoArray = carrito ? JSON.parse(carrito) : null;
    
    if(!carritoArray)
    {
      carritoArray = [];
    }

    carritoArray.push(producto);
    await AsyncStorage.setItem('carritoActual',JSON.stringify(carritoArray));
    // //console.log('agregado')
  }

  function enviarWhatsapp(numero)
  {
    Linking.openURL('whatsapp://send?text=Necesito información sobre: &phone=' + numero);
  }


export default function Producto({route , navigation}){

    const [producto , SetProducto] = React.useState(0);
    const [comprarForm , SetComprarForm] = React.useState(0);
    const [nombre , SetNombre] = React.useState(authToken == null || authToken == '' ? '' : userRef.nombre);
    const [email , SetEmail] = React.useState(authToken == null || authToken == '' ? '' : userRef.email);
    const [telefono , SetTelefono] = React.useState(authToken == null || authToken == '' ? '' : userRef.telefono);
    const [cedula , SetCedula] = React.useState(authToken == null || authToken == '' ? '' : userRef.cedula);
    const [tipoPago , SetTipoPago] = React.useState('');
    const [detalles , SetDetalles] = React.useState('');
    const [numeroReferencia , SetNumeroReferencia] = React.useState('');
    const [fechaPago , SetFechaPago] = React.useState('');
    const [vista , SetVista] = React.useState('producto');
    const [tienda , SetTienda] = React.useState(getTienda(route.params.userId));
    const [cantidad , SetCantidad] = React.useState(1);
    const [productoId , SetProductoId] = React.useState(route.params.productoId);
    const [userId , SetUserId] = React.useState(route.params.userId);

    
    // //console.log(TiendaData);
    // async function getProductos(userId)
    // {
    //     const coll = collection(Firebase, "productos");
    //     const q = query(coll, where("userId", "==", userId));
    //     const snapshot = await getCountFromServer(q);
    //     const cantidadProductos = snapshot.data().count;

    //     return cantidadProductos;
    // }

    const Item = ({item , navigation}) => (
      <View style={styles.itemLista}>
        <TouchableOpacity onPress={() => verProducto(item , item.id)}>
         
            <Image source={{ uri: item.image }} style={styles.imagenLista}/>
            
        </TouchableOpacity>
        
        <View style={{ flexDirection: 'row' , justifyContent: 'space-between' , alignItems: 'center'}}>
          <Text style={styles.tituloLista}>{item.nombre}</Text>
          <Text style={styles.tituloLista}>${item.precio}</Text>
        </View>
      </View>
    );
    
    const Comment = ({item , navigation}) => (
      <View style={styles.comentarioBox}>
        <View style={styles.comentario}>
          <View style={styles.puntosBox}>
            <Text><Ionicons name="star" size={15} color={ item.puntos >= 1 ? '#FFFF00' : '#9b9b9b'}></Ionicons></Text>
            <Text><Ionicons name="star" size={15} color={ item.puntos >= 2 ? '#FFFF00' : '#9b9b9b'}></Ionicons></Text>
            <Text><Ionicons name="star" size={15} color={ item.puntos >= 3 ? '#FFFF00' : '#9b9b9b'}></Ionicons></Text>
            <Text><Ionicons name="star" size={15} color={ item.puntos >= 4 ? '#FFFF00' : '#9b9b9b'}></Ionicons></Text>
            <Text><Ionicons name="star" size={15} color={ item.puntos >= 5 ? '#FFFF00' : '#9b9b9b'}></Ionicons></Text>
          </View>
          <Text>"{item.comentario}"</Text>
          <View style={{ flexDirection: 'row' , justifyContent: 'space-between' , alignItems: 'center'}}>
            <Text style={styles.tituloLista}>{item.nombre}</Text>
            {/* <Text style={styles.tituloLista}>{console.log(item.fechaCreado.toString())}</Text> */}
          </View>
        </View>
      </View>
    );

    function verProducto(producto , productoId)
    {
      getComentariosProducto(productoId).then(() => {
        SetProducto(producto);
        SetProductoId(productoId);
        SetVista('producto');
      })
      
    }
    
    function registrarCompra(navigation)
    {
      
      try{
        
        var newOrden = collection(Firebase, "ordenes");
        
        addDoc(newOrden, {
          email: email,
          nombre: nombre,
          telefono: telefono,
          cedula: cedula,
          detalles: detalles,
          numeroReferencia: numeroReferencia,
          fechaPago: fechaPago,
          tipoPago: tipoPago,
          userId: authToken,
          tiendaId: TiendaData.id,
          tiendaData: TiendaData,
          userTiendaId: producto.userId,
          total: producto.precio * cantidad,
          productos: [{
            productoId:productoId,
            nombre:producto.nombre,
            precio: producto.precio,
            cantidad: cantidad,
            tiendaId: TiendaData.id,
            userTiendaId: producto.userId,
          }],
          estatus: 1,
          fechaCreado: Timestamp.fromDate(new Date()),
        }).then((docRef) => {
          SetComprarForm(0);
          navigation.navigate('Notificacion' , {
              type: 'success',
              title: '¡Compra realizada!',
              message: 'Estaremos procesando los datos de su compra en breve.'
          })
          // Linking.openURL('whatsapp://send?text=Hola soy '+ nombre +' quiero comprar el producto '+ producto.nombre +' mi email es: '+ email +' teléfono: '+ telefono +', '+ detalles +' , enviado desde la app Uruguay Altoque&phone=' + TiendaData.whatsapp);
          // alert('Orden realizada con exito');
        });
      }catch(error){
        console.log(error);
      }finally{
        
      }
      
    }

    function agregarCarrito(navigation)
    {console.log(producto);
      let productoCarro = {
                        productoId:productoId,
                        image:producto.image,
                        nombre:producto.nombre,
                        precio: producto.precio,
                        cantidad: cantidad,
                        tiendaId: TiendaData.id,
                        userId: producto.userId,
                      }

      setCarrito(productoCarro);

      navigation.navigate('Notificacion' , {
          type: 'success',
          title: '¡Producto agregado!',
          message: 'Se agregó exitosamente al carrito.'
      })
    }
    
      if(productoId && producto == 0)
      {
        authFn().then(() => {
          getProducto(productoId).then((productoRef) => {
            getComentariosProducto(productoId).then(() => {
              IMAGENES.length = 0
              IMAGENES.push(productoRef.image)
              if(productoRef.image2)
              {
                IMAGENES.push(productoRef.image2)
              }
              if(productoRef.image3)
              {
                IMAGENES.push(productoRef.image3)
              }
              if(productoRef.image4)
              {
                IMAGENES.push(productoRef.image4)
              }
              if(productoRef.image5)
              {
                IMAGENES.push(productoRef.image5)
              }
              SetProducto(productoRef);
            })
            // //console.log(productoRef);
            // //console.log(route.params.productoId);
            // producto = productoRef;
            //console.log('tienda ' + userId)
          });
        })
      }

      if(TiendaData)
      {
        getComentarios(TiendaData.id);
        FOTOSTIENDA.length = 0;
        
        if(TiendaData.foto1)
        {
          FOTOSTIENDA.push(TiendaData.foto1)
        }
        if(TiendaData.foto2)
        {
          FOTOSTIENDA.push(TiendaData.foto2)
        }
        if(TiendaData.foto3)
        {
          FOTOSTIENDA.push(TiendaData.foto3)
        }
        if(TiendaData.foto4)
        {
          FOTOSTIENDA.push(TiendaData.foto4)
        }
        if(TiendaData.foto5)
        {
          FOTOSTIENDA.push(TiendaData.foto5)
        }
      }
      getRelacionados(userId);

      // //console.log(RELACIONADOS);

    return(
    <View style={{flex: 1}}>
      <BusquedaPrincipal/>
      <ImageBackground source={require('../img/fondo.jpg')} resizeMode="cover" style={styles.imageFondo}>
        <ScrollView style={styles.containerSafeArea}>
          <View style={styles.container}>
            
            {/* <View style={styles.searchTop}>
              
              <View style={styles.searchTopInput}>
                <Ionicons style={styles.searchIcon} name="search" size={20} color="#b51f22"/>
                <TextInput
                  placeholder="Buscar productos o tiendas"
                  style={styles.inputBuscar}
                />
              </View>
              <TouchableOpacity>
                <Ionicons style={styles.heartIcon} name="heart" size={20} color="#ffffff"/>
              </TouchableOpacity>
            </View> */}

                {producto ? <>
                  

                    {/* <Image style={styles.imagenProducto}
                    source={{ uri : producto.image}} /> */}
                        
                      <View style={styles.fotosContainer}>
                        <View style={styles.topNavContainer}>
                          <TouchableOpacity onPress={() => navigation.goBack()} >
                              <Ionicons style={styles.arrowIcon} name="arrow-back" size={25} color="#000000"/>
                          </TouchableOpacity>
                        </View>
                        

                        <FlatList
                          data={IMAGENES}
                          // keyExtractor={item => item.index}
                          style={{width: WIDTHSCREEN}}
                          horizontal={true}
                          pagingEnabled
                          renderItem={({item}) => 
                            <View style={{width: WIDTHSCREEN}}>
                              <Image source={{ uri: item }} style={{width: WIDTHSCREEN, height: 200}}/>
                            </View>
                          }
                        />
                      </View>

                        

                      {/* <View style={{ flexDirection: 'row' , width: '100%', overflow : 'auto' }}>
                        <ImageBackground source={{uri: producto.image}} resizeMode="cover" style={styles.imagenProducto}>  
                        </ImageBackground>

                        {producto.image2 && <>
                          <ImageBackground source={{uri : producto.image2}} resizeMode="cover" style={styles.imagenProducto}>  
                          </ImageBackground>
                        </>}
                        {producto.image3 && <>
                          <ImageBackground source={{uri: producto.image3}} resizeMode="cover" style={styles.imagenProducto}>  
                          </ImageBackground>
                        </>}
                        {producto.image4 && <>
                          <ImageBackground source={{uri : producto.image4}} resizeMode="cover" style={styles.imagenProducto}>  
                          </ImageBackground>
                        </>}
                        {producto.image5 && <>
                          <ImageBackground source={{ uri: producto.image5}} resizeMode="cover" style={styles.imagenProducto}>  
                          </ImageBackground>
                        </>}
                      </View>   */}
                      
                     
                      <View style={{width: '100%' , backgroundColor: '#b51f22'}}>
                        <View style={{width: '80%' ,flexDirection: 'row' , justifyContent: 'space-between' , marginHorizontal: 'auto', marginLeft: 'auto' , marginRight: 'auto'}}>
                          <TouchableOpacity onPress={() => SetVista('producto')}>
                            <Text style={{ fontSize: 18 , fontWeight: 'bold', color: '#ffffff' , paddingVertical: 10, borderBottomWidth: 5 , borderBottomColor: vista == 'producto' ? '#ffffff' : '#b51f22' }}>Producto</Text>
                          </TouchableOpacity>
                          <TouchableOpacity onPress={() => SetVista('tienda')}>
                            <Text style={{ fontSize: 18 , fontWeight: 'bold', color: '#ffffff' , paddingVertical: 10, borderBottomWidth: 5 , borderBottomColor: vista == 'tienda' ? '#ffffff' : '#b51f22' }}>Tienda</Text>
                          </TouchableOpacity>
                          <TouchableOpacity onPress={() => SetVista('otros')}>
                            <Text style={{ fontSize: 18 , fontWeight: 'bold', color: '#ffffff' , paddingVertical: 10, borderBottomWidth: 5 , borderBottomColor: vista == 'otros' ? '#ffffff' : '#b51f22' }}>Más Productos</Text>
                          </TouchableOpacity>
                        </View>
                      </View>

                    {vista == 'producto' && <>
                      <Text style={styles.titulo}>{producto.nombre}</Text>

                      <View style={{width:'80%',marginTop:15}}>

                      <View style={{  marginBottom: 10 }}>
                        <View style={{flexDirection: 'row' , justifyContent: 'space-between' , marginBottom: 10}}>
                          
                          <Text style={{ fontSize: 20 , fontWeight: 'bold', color: '#b51f22' }}>$ {producto.precio}</Text>
                        
                          <Text style={{ fontSize: 18 , fontWeight: 'bold', }}>{producto.nombre }</Text>
                          
                        </View>

                        <Text>{producto.descripcion}</Text>

                        {producto.estadoProducto ? <>
                          <Text style={{ marginTop: 10, marginBottom: 10 , fontWeight: 'bold' }}>Estado: { estadoProducto(producto.estadoProducto)}</Text>
                        </> : <></>}

                        <Text style={{ marginTop: 10, marginBottom: 10 , fontWeight: 'bold' }}>{producto.categoria} {producto.subcategoria ? ' | ' + producto.subcategoria : ''}</Text>

                        <FlatList
                          data={COMENTARIOSPRODUCTO}
                          renderItem={({item}) => <Comment item={item} navigation={navigation}/>}
                          keyExtractor={item => item.id}
                          horizontal={true}
                          style={{width:'100%'}}

                        />

                        

                      </View>

                        <View style={{ justifyContent: 'center'}}>
                        { authToken == null || authToken == '' ? <>
                              <TouchableOpacity style={styles.botonLogin} onPress={() => navigation.navigate('LoginForm')}>
                                <Ionicons style={styles.heartIcon} name="log-in" size={20} color="#ffffff"/>
                                <Text style={styles.botonCompraText}>Iniciar Sesión</Text>
                              </TouchableOpacity>
                            </> : <>
                              <TouchableOpacity style={styles.botonCompra} onPress={() => SetComprarForm(1)}>
                                <Ionicons style={styles.heartIcon} name="cash" size={20} color="#ffffff"/>
                                <Text style={styles.botonCompraText}>Comprar</Text>
                              </TouchableOpacity>
                              
                              <View style={{flexDirection: 'row' , justifyContent: 'space-between'}}> 
                                <TouchableOpacity style={styles.botonAgregarFav} onPress={() => agregarFavorito(producto , productoId , navigation)}>
                                  <Ionicons style={styles.heartIcon} name="heart" size={20} color="#ffffff"/>
                                  <Text style={styles.botonCompraText}></Text>
                                </TouchableOpacity>

                                <TouchableOpacity style={styles.botonAgregarFav} onPress={() => agregarCarrito(navigation)}>
                                  <Text style={styles.botonCompraText}>
                                    <Ionicons style={styles.heartIcon} name="cart" size={20} color="#ffffff"/>
                                  </Text>
                                </TouchableOpacity>
                              </View>

                              <TouchableOpacity style={styles.botonComentar} onPress={() => navigation.navigate('Review' , {
                                            nombre: userRef.nombre,
                                            userId : authToken,
                                            productoId : productoId,
                                            tiendaId : TiendaData.id,
                                            userTiendaId : producto.userId,
                                        })}>
                                <Ionicons style={{padding: 10}} name="chatbox" size={20} color="#b51f22"/>
                                <Text style={styles.botonComentarText}>
                                  Comentar
                                </Text>
                              </TouchableOpacity>
                            </> 
                        }
                          
                          
                        </View>

                        {comprarForm ? <>
                          <TouchableOpacity style={styles.botonLogin} onPress={() => SetComprarForm(0)}>
                            <Ionicons style={styles.heartIcon} name="close-circle" size={20} color="#ffffff"/>
                            <Text style={styles.botonCompraText}>Cancelar</Text>
                          </TouchableOpacity>
                          
                          <Text style={styles.subtituloText}>Datos Personales</Text>

                          <TextInput 
                              style={styles.input}
                              placeholder="Nombre"
                              onChangeText={SetNombre}
                              value={nombre}
                          />
                          <TextInput 
                              style={styles.input}
                              placeholder="Email"
                              onChangeText={SetEmail}
                              value={email}
                          />
                          <TextInput 
                              style={styles.input}
                              placeholder="Cédula"
                              onChangeText={SetCedula}
                              value={cedula}
                          />
                          <TextInput 
                              style={styles.input}
                              placeholder="Telêfono"
                              onChangeText={SetTelefono}
                              value={telefono}
                          />
                          <TextInput 
                              style={styles.input}
                              multiline
                              numberOfLines={4}
                              placeholder="Detalles"
                              onChangeText={SetDetalles}
                              value={detalles}
                          />

                          <Text style={styles.subtituloText}>Datos de pago</Text>

                          <View style={{flexDirection: 'row' , justifyContent: 'space-between' , marginBottom: 5}}>
                                <TouchableOpacity 
                                        style={tipoPago == 1 ? styles.buttonActivo : styles.button}
                                        onPress={() => 
                                          SetTipoPago(1)
                                        }>
                                        <Text style={tipoPago == 1 ? styles.buttonTextActivo :  styles.buttonText}>Pago seguro</Text>
                                </TouchableOpacity>
                                <TouchableOpacity 
                                        style={tipoPago == 2 ? styles.buttonActivo : styles.button}
                                        onPress={() => 
                                        SetTipoPago(2)
                                        }>
                                        <Text style={tipoPago == 2 ? styles.buttonTextActivo :  styles.buttonText}>Pago en tienda</Text>
                                </TouchableOpacity>
                            </View>

                            <View>
                                        {tipoPago == 1 ? <>
                                            <TextInput 
                                                style={styles.input}
                                                multiline
                                                numberOfLines={4}
                                                placeholder="Detalles de pago / Numero de Referencia"
                                                onChangeText={SetNumeroReferencia}
                                                value={numeroReferencia}
                                            />
                                            <TextInput 
                                                style={styles.input}
                                                multiline
                                                numberOfLines={1}
                                                placeholder="Fecha de Pago"
                                                onChangeText={SetFechaPago}
                                                value={fechaPago}
                                            />
                                        </> : <></>}
                                        {tipoPago == 2 ? <>
                                          <TextInput 
                                                style={styles.input}
                                                multiline
                                                numberOfLines={4}
                                                placeholder="Detalles de pago / Numero de Referencia"
                                                onChangeText={SetNumeroReferencia}
                                                value={numeroReferencia}
                                            />
                                            <TextInput 
                                                style={styles.input}
                                                multiline
                                                numberOfLines={1}
                                                placeholder="Fecha de Pago"
                                                onChangeText={SetFechaPago}
                                                value={fechaPago}
                                            />
                                        </> : <></>}
                            </View>  

                          <TouchableOpacity style={styles.botonLogin} onPress={() => registrarCompra(navigation)}>
                            <Ionicons style={styles.heartIcon} name="checkbox-outline" size={20} color="#ffffff"/>
                            <Text style={styles.botonCompraText}>Confirmar Compra</Text>
                          </TouchableOpacity>
                        </> : <></>}

                        

                        {/* { TiendaData.whatsapp && <>
                        <TouchableOpacity style={{ backgroundColor: '#25D366' , padding: 10 , marginBottom: 10  }} onPress={() => enviarWhatsapp(TiendaData.whatsapp)}>
                          <Text style={{ color: '#ffffff' , fontSize: 19 , marginBottom: 10 , textAlign: 'center'  }}><Ionicons name="logo-whatsapp" size={20} color="#ffffff"/> Contactar por WhatsApp</Text>
                        </TouchableOpacity>
                        </> }  */}

                        <TouchableOpacity style={styles.botonVerProductos} onPress={() => navigation.goBack()}>
                          <Text style={styles.botonCompraText}>
                            Ver más productos
                          </Text>
                        </TouchableOpacity>

                        {authToken ? <>
                        
                          <Comentarios productoId={productoId} navigation={navigation} nombreCliente={nombre} tiendaId={TiendaData.id} userId={authToken} productoNombre={producto.nombre}/>
                        
                        </> : <></>}
                        
                        

                      </View>
                    </>}
                    
                    {vista == 'tienda' && <>
                      <Text style={styles.titulo}>{TiendaData.name}</Text>


                      <View style={{width:'80%',marginTop:15, overflow: 'hidden'}}>
                        <View style={{ backgroundColor: '#ffffff' , marginBottom: 10 }}>
                          <Image style={styles.imagenProducto}
                          source={{ uri : TiendaData.image}} />
                          
                          <Text style={{ padding: 15 }}>{TiendaData.about}</Text>
                        </View>

                        <FlatList
                          data={COMENTARIOS}
                          renderItem={({item}) => <Comment item={item} navigation={navigation}/>}
                          keyExtractor={item => item.id}
                          horizontal={true}
                          style={{width:'100%'}}

                        />

                      {TiendaData.tiendaFisica && <>
                        <Text style={styles.titulo}>Nuestra Tienda</Text>
                        <FlatList
                          data={FOTOSTIENDA}
                          // keyExtractor={item => item.index}
                          style={{width: WIDTHSCREEN , marginBottom: 20}}
                          horizontal={true}
                          pagingEnabled
                          renderItem={({item}) => 
                            <View style={{width: WIDTHSCREEN}}>
                              <Image source={{ uri: item }} style={{width: WIDTHSCREEN, height: 200}}/>
                            </View>
                          }
                        />
                      </>}  
                        {/* { TiendaData.email && <>
                        <View style={{ backgroundColor: '#ffffff' , padding: 15 , marginBottom: 10 }}>
                          <Text style={{ fontSize: 19 , marginBottom: 10 , textAlign: 'center' }}>Email</Text>
                          <Text>{TiendaData.email}</Text>
                        </View>
                        </> } */}
                        
                        {/* { TiendaData.direccion && <>
                        <View style={{ backgroundColor: '#ffffff' , padding: 15 , marginBottom: 10 }}>
                          <Text style={{ fontSize: 19 , marginBottom: 10 , textAlign: 'center' }}>Dirección</Text>
                          <Text>{TiendaData.direccion}</Text>
                        </View>
                        </> } */}

                        {/* { TiendaData.telefono && <>
                        <View style={{ backgroundColor: '#ffffff' , padding: 15 , marginBottom: 10 }}>
                          <Text style={{ fontSize: 19 , marginBottom: 10 , textAlign: 'center'  }}>Teléfono</Text>
                          <Text>{TiendaData.telefono}</Text>
                        </View>
                        </> } */}
                        
                        {/* { TiendaData.whatsapp && <>
                        <TouchableOpacity style={{ backgroundColor: '#25D366' , padding: 15 , marginBottom: 10 }} onPress={() => enviarWhatsapp(TiendaData.whatsapp)}>
                          <Text style={{ color: '#ffffff' , fontSize: 19 , marginBottom: 10 , textAlign: 'center'  }}><Ionicons name="logo-whatsapp" size={20} color="#ffffff"/> Contactar por WhatsApp</Text>
                        </TouchableOpacity>
                        </> } */}
                        
                      </View>
                    </>}
                   
                    {vista == 'otros' && <>
                    {/* Más productos de */}
                      <Text style={styles.titulo}>{TiendaData.name}</Text>


                      <View style={{width:'80%',marginTop:15}}>

                      <FlatList
                        data={RELACIONADOS}
                        renderItem={({item}) => <Item item={item} navigation={navigation}/>}
                        keyExtractor={item => item.id}
                        contentContainerStyle={styles.gridLista}
                        style={{width:'100%'}}
                        numColumns={2}
                      />
                        
                      </View>
                    </>}


                  </> : <>
                    <Text style={{marginTop: 20}}>Cargando información</Text>
                  </>}
          
              

          </View>
        </ScrollView>

        { authToken == null || authToken == '' ? <>
            
            </> : <>
                <Botones navigation={navigation}/>      
            </> 
        }
    </ImageBackground>
    </View>
    );
}