import React from "react";
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
// import Home from "./Home";
import Tienda from "./Tienda";
import Producto from "./Producto";
import Carrito from "./Carrito";
import Compras from "./Compras";
import Perfil from "./Perfil";
import ProductosListaAuth from "./ProductosListaAuth";
import Favoritos from "./Favoritos";
import Notificacion from "./Notificacion";
import Comentar from "./Comentar";
import Review from "./Review";

const Stack = createNativeStackNavigator();

export default function User({navigation , reload}){
    return(
        <NavigationContainer>
            <Stack.Navigator>
                <Stack.Screen name="ProductosListaAuth" component={ProductosListaAuth} initialParams={{ reload: reload }}  options={{headerShown: false}} />
                {/* <Stack.Screen name="Home" component={Home}  options={{headerShown: false}} /> */}
                <Stack.Screen name="Tienda" component={Tienda} options={{headerShown: false}} />
                <Stack.Screen name="Favoritos" component={Favoritos} options={{headerShown: false}} />
                <Stack.Screen name="Producto" initialParams={{ reload: reload }} component={Producto} options={{headerShown: false}} />
                <Stack.Screen name="Carrito" component={Carrito} options={{headerShown: false}} />
                <Stack.Screen name="Compras" component={Compras} options={{headerShown: false}} />
                <Stack.Screen name="Comentar" component={Comentar} options={{headerShown: false}} />
                <Stack.Screen name="Review" component={Review} options={{headerShown: false}} />
                <Stack.Screen name="Perfil" initialParams={{ reload: reload }} component={Perfil} options={{headerShown: false}} />
                <Stack.Screen name="Notificacion" component={Notificacion} options={{headerShown: false}} />
            </Stack.Navigator>
        </NavigationContainer>
    );
}