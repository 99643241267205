// Import the functions you need from the SDKs you need
// Correr npm install firebase
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBcXIrZ2zAA3WczO0ao0P1uTWYu5KmLwf0",
  authDomain: "joydisenos-93dc0.firebaseapp.com",
  databaseURL: "https://joydisenos-93dc0.firebaseio.com",
  projectId: "joydisenos-93dc0",
  storageBucket: "joydisenos-93dc0.appspot.com",
  messagingSenderId: "110798291174",
  appId: "1:110798291174:web:3412fbebcba30b71a2bb27",
  measurementId: "G-73WVNWJG4B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export default db;
