import React from "react";
import { StyleSheet ,View , Image , TextInput , ScrollView , TouchableOpacity , Text } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as ImagePicker from 'expo-image-picker';
import { doc, getDoc , updateDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadString } from "firebase/storage";
import Firebase from "../db/Firebase";
import BotonesTienda from "../components/BotonesTienda";

const styles = StyleSheet.create({
    container:{
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonGrande: {
        padding: 14,
        textAlign: 'center',
        backgroundColor: '#b51f22',
        color: '#ffffff',
        // borderRadius: 30,
        marginTop: 10,
        marginBottom: 10,
        width: '100%',
        maxWidth: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonGrandeOutline: {
        padding: 14,
        textAlign: 'center',
        // backgroundColor: '#ffffff',
        borderColor: '#b51f22',
        borderWidth: 1,
        color: '#b51f22',
        // borderRadius: 30,
        marginTop: 30,
        width: '100%',
        maxWidth: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonGrandeOutlineTexto: {
        color: '#b51f22',
    },
    buttonGrandeTexto: {
        color: '#ffffff',
    },
    input: {
        padding: 14,
        borderStyle: 'solid',
        borderColor: '#b51f22',
        borderWidth: 1,
        // borderRadius: 30,
        marginBottom: 5,
        width: '100%',
        maxWidth: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    inputTitulo: {
        padding: 14,
        borderBottomStyle: 'solid',
        borderBottomColor: '#b51f22',
        borderBottomWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: '#b51f22',
        borderTopWidth: 1,
        marginVertical: 10,
        width: '100%',
        maxWidth: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    titulo: {
        fontWeight: 'bold',
        fontSize: 23.5,
        margin: 20,
        textAlign: 'center',
      },
    logo: {
        width: 150,
        height: 187,
        marginBottom: 20,
        marginRight: 'auto',
        marginLeft: 'auto',
      },
      imgStyle : { 
        width: '60%', 
        height: 200 , 
        // borderRadius: 30, 
        marginRight: 'auto' , 
        marginLeft: 'auto' , 
        marginBottom: 10
    }
});



function LogoutFn(reload)
{
    AsyncStorage.setItem('authToken', '');
    AsyncStorage.setItem('tipoUser', '');
    // window.location.reload(false);
    reload('' , '' , '')
}

let authToken = null;
let docRef = null;
let docSnap = null;
let userRef = {};

async function authFn () {

    authToken = await AsyncStorage.getItem('authToken');
   
    docRef = authToken == '' || authToken == null  
        ? null 
        : doc(Firebase , 'users' , authToken);
    docSnap = authToken == '' || authToken == null  
        ? null 
        : await getDoc(docRef);
    userRef = authToken == '' || authToken == null  
        ? {} 
        : docSnap.data();
}

authFn()




async function guardarDatos(data , image = null , anverso = null , reverso = null , navigation)
{
    let nombreImagen;
    let storage;
    let storageRef;

    if(image && image.search('https://') == -1)
    {
        nombreImagen = 'perfiles/perfil-'+authToken+'.jpg';
        storage = getStorage();
        storageRef = ref(storage, nombreImagen);
        
        await uploadString(storageRef, image, 'data_url').then( async(snapshot) => {
            await getDownloadURL(storageRef).then((url) => {
                data.image = url;
                
            })
        });

        // data.image = nombreImagen;
    }


    if(anverso && anverso.search('https://') == -1)
    {
        nombreImagen = 'perfiles/perfil-dni-anverso'+authToken+'.jpg';
        storage = getStorage();
        storageRef = ref(storage, nombreImagen);
        
        await uploadString(storageRef, anverso, 'data_url').then(async (snapshot) => {
             await getDownloadURL(storageRef).then((url) => {
                data.anverso = url;
               
            })
           
        });

        // data.image = nombreImagen;
    }
    
    if(reverso && reverso.search('https://') == -1)
    {
        nombreImagen = 'perfiles/perfil-dni-reverso'+authToken+'.jpg';
        storage = getStorage();
        storageRef = ref(storage, nombreImagen);
        
        await uploadString(storageRef, reverso, 'data_url').then(async (snapshot) => {
            await getDownloadURL(storageRef).then((url) => {
                data.reverso = url;

            })
           
        });

        // data.image = nombreImagen;
    }

    await updateDoc(doc(Firebase, "users", authToken),
        data,
    );

    navigation.navigate('Notificacion' , {
        type: 'success',
        title: '¡Datos guardados!',
        message: 'Sus datos se actualizaron correctamente.'
      })
}


async function pickImage()
{
    let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
  
     
  
      if (!result.canceled) {
       
        return result.assets[0].uri
      }

      return null

}

export default function PerfilTienda({route , navigation}){
    
    const [loaded, SetLoaded] = React.useState(0);
    const [nombre, SetNombre] = React.useState(userRef.nombre);
    const [email, SetEmail] = React.useState(userRef.email);
    const [telefono, SetTelefono] = React.useState(userRef.telefono ? userRef.telefono : '');
    const [cedula, SetCedula] = React.useState(userRef.cedula ? userRef.cedula : '');
    const [image, setImage] = React.useState(null);
    const [anverso, SetAnverso] = React.useState(userRef.anverso ? userRef.anverso : '');
    const [reverso, SetReverso] = React.useState(userRef.reverso ? userRef.reverso : '');

    if(!loaded)
    {
        authFn().then(() => {
                
                SetNombre(userRef.nombre)
                SetEmail(userRef.email)
                SetTelefono(userRef.telefono ? userRef.telefono : '')
                SetCedula(userRef.cedula ? userRef.cedula : '')
                setImage(userRef.image ? userRef.image : '')
                SetAnverso(userRef.anverso ? userRef.anverso : '')
                SetReverso(userRef.reverso ? userRef.reverso : '')
                SetLoaded(1)
        })
    }

    // if(userRef.image && image == null)
    // {
    //     getDownloadURL(ref( getStorage() , userRef.image)).then((url) => {
    //         setImage(url)
    //     })
    // }
    
    
    
    
    return (
        <View style={{flex: 1}}>

            {loaded ? <>
                <ScrollView style={styles.container}>

                    <Text style={styles.titulo}>Perfil</Text>

                    <Image
                    style={styles.logo}
                    source={ require('../img/logo.png')}
                    />

                    {image ? <Image 
                            source={{ uri: image }} 
                            style={styles.imgStyle} 
                        /> : <></>}

                    <TouchableOpacity style={styles.buttonGrande}>
                        <Text style={styles.buttonGrandeTexto} onPress={() => pickImage().then((image) => {setImage(image)})}>
                            Seleccionar Imagen
                        </Text>
                    </TouchableOpacity>

                    <TextInput 
                        style={styles.input}
                        placeholder="Nombre"
                        onChangeText={SetNombre}
                        value={nombre}
                    />
                    
                    <TextInput 
                        style={styles.input}
                        placeholder="Email"
                        onChangeText={SetEmail}
                        value={email}
                    />
                    
                    <TextInput 
                        style={styles.input}
                        placeholder="Teléfono"
                        onChangeText={SetTelefono}
                        value={telefono}
                    />
                    
                    <TextInput 
                        style={styles.input}
                        placeholder="Cédula"
                        onChangeText={SetCedula}
                        value={cedula}
                    />

                    <TouchableOpacity style={styles.buttonGrande}>
                        <Text style={styles.buttonGrandeTexto} onPress={() => {guardarDatos({   
                                                nombre: nombre,
                                                email: email,
                                                telefono: telefono,
                                            } , image , anverso , reverso , navigation)}}>
                            Guardar
                        </Text>
                    </TouchableOpacity>
                    
                    <TouchableOpacity style={styles.buttonGrandeOutline}>
                        <Text style={styles.buttonGrandeOutlineTexto} onPress={() => LogoutFn(route.params.reload)}>
                            Cerrar Sesión
                        </Text>
                    </TouchableOpacity>
                </ScrollView>
            </> : <></>}
            <BotonesTienda navigation={navigation}/>
        </View>
    );
}