import React from "react";
import { View , TouchableOpacity , FlatList ,Text , StyleSheet  , TextInput} from "react-native";
import { doc, getDoc , getDocs , updateDoc , collection ,  addDoc , Timestamp , query , where , limit , deleteDoc , orderBy} from "firebase/firestore";
import Ionicons from '@expo/vector-icons/Ionicons';
import Firebase from "../db/Firebase";
import db from "../db/Firestore";
import { useRoute } from '@react-navigation/native';

const styles = StyleSheet.create({
    container:{
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    input: {
        padding: 14,
        borderStyle: 'solid',
        borderColor: '#b51f22',
        borderWidth: 1,
        // borderRadius: 30,
        marginBottom: 10,
        width: '100%',
        // maxWidth: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    pregunta: {
        width: '100%',
        color: '#000000',
        padding: 5,
        backgroundColor: '#ffffff',
    },
    respuesta: {
        width: '100%',
        color: '#000000',
        padding: 5,
        paddingLeft: 15,
        backgroundColor: '#ffffff',
    },
    preguntaNombre: {
        width: '100%',
        color: '#000000',
        borderBottomWidth: 1,
        borderBottomColor: '#b51f22',
        padding: 5,
        backgroundColor: '#ffffff',
        textAlign: 'right',
    },
    buttonSend:{
        position: 'absolute',
        top: 0,
        // transform: 'translateY(-50%)',
        right: 0,
        padding: 15,
    }
});

const DATA = [];

export default function Preguntas({tiendaId , navigation})
{
    const [respuesta , SetRespuesta] = React.useState('');
    const [loaded , SetLoaded] = React.useState(0);
    
    async function getConsultas(tiendaId)
    {
        const q = query(
                        collection(Firebase, "consultas") , 
                        where('estatus' , '==' , 0) , 
                        where('tiendaId' , '==' , tiendaId) , 
                        orderBy('fechaCreado' , 'desc')
                        );

        const querySnapshot = await getDocs(q);
        
        DATA.length = 0;
        
        querySnapshot.forEach((doc) => {
                DATA.push({
                id: doc.id, ...doc.data(),
                });
        });

        return DATA;
    }   

    async function enviarRespuesta(respuesta , consultaId , navigation)
    {
        if(respuesta == '')
        {
            navigation.navigate('Notificacion' , {
                type: 'warning',
                title: 'Datos Requeridos',
                message: 'en campo respuesta es obligatorio.'
            })

            return true
        }
        
        if(respuesta.length < 2)
        {
            navigation.navigate('Notificacion' , {
                type: 'warning',
                title: 'Datos Requeridos',
                message: 'en campo respuesta debe tener mínimo 2 caracteres.'
            })

            return true
        }

        await updateDoc(doc(Firebase, "consultas", consultaId),
                {respuesta: respuesta , estatus: 1},
            );
    }


    if(loaded == 0)
    {
        console.log(tiendaId);
        getConsultas(tiendaId).then(() => {
            SetLoaded(1);
            console.log(DATA);
        });
    }

    // console.log(DATA)
    function addConsulta(consultaId)
    {    
        // console.log(comentario);
        enviarRespuesta(respuesta , consultaId , navigation).then(() => {
            SetLoaded(0)
        });
    }

    return (
        <View style={styles.container}>
            {loaded ? <>
                
                <FlatList
                    data={DATA}
                    keyExtractor={item => item.id}
                    // style={styles.comentarios}
                    renderItem={({item}) => 
                        <>
                            <Text style={styles.pregunta}>
                                {item.consulta}
                            </Text>
                            <Text style={styles.preguntaNombre}>Producto: {item.productoNombre} - Cliente:{ item.nombre }</Text>

                            <View>
                            <TextInput
                                placeholder="Responder"
                                style={styles.input}
                                onChangeText={SetRespuesta}
                            />
                            <TouchableOpacity style={styles.buttonSend} onPress={() => {
                                addConsulta(item.id)
                            }}>
                                <Text>
                                    <Ionicons name="send" size={15} color='#b51f22'/>
                                </Text>
                            </TouchableOpacity>
                        </View>
                        </>
                    }
                    />

               
            </> : <></>}

            
        </View>
    );
}