import React from "react";
import { ImageBackground , StyleSheet ,View , Image , TextInput , ScrollView , TouchableOpacity , FlatList , Text } from "react-native";
import Botones from "../components/Botones";
import Ionicons from '@expo/vector-icons/Ionicons';
import Firebase from "../db/Firebase";
import { doc, setDoc , getDoc , getDocs , updateDoc , addDoc , collection , Timestamp , query , where, Firestore, orderBy} from "firebase/firestore";
import AsyncStorage from "@react-native-async-storage/async-storage";

const styles = StyleSheet.create({
    container:{
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    botonLista: {
        flexDirection: 'row' , 
        justifyContent: 'space-between',
        width: '100%',
        padding: 9,
        backgroundColor: '#ffffff',
        borderWidth: 1,
        borderColor: '#b51f22',
        marginBottom: 8,
        // borderRadius: 15,
        
      },
      botonListaTexto: {
        color: '#b51f22',
      },    
      imageFondo: {
        flex: 1,
      },
      containerSafeArea: {
        flex: 1,
        marginTop: 0,
        // minHeight: '100vh',
      },
    buttonGrande: {
        padding: 14,
        textAlign: 'center',
        backgroundColor: '#b51f22',
        color: '#ffffff',
        // borderRadius: 30,
        marginTop: 10,
        width: '100%',
        // maxWidth: '300px',
    },
    buttonGrandeOutline: {
        padding: 14,
        textAlign: 'center',
        // backgroundColor: '#ffffff',
        borderColor: '#b51f22',
        borderWidth: 1,
        color: '#b51f22',
        // borderRadius: 30,
        marginTop: 30,
        width: '100%',
        maxWidth: '300px',
    },
    buttonGrandeOutlineTexto: {
        color: '#b51f22',
    },
    buttonGrandeTexto: {
        color: '#ffffff',
        textAlign: 'center',
    },
    input: {
        padding: 14,
        borderStyle: 'solid',
        borderColor: '#b51f22',
        borderWidth: 1,
        // borderRadius: 30,
        marginBottom: 5,
        width: '100%',
        maxWidth: '300px',
    },
    titulo: {
        fontWeight: 'bold',
        fontSize: 23.5,
        margin: 20,
        textAlign: 'center',
      },
    logo: {
        width: 150,
        height: 187,
        marginBottom: 10,
        marginRight: 'auto',
        marginLeft: 'auto',
      },
    buttonPequeno:{
        marginBottom: 10,
    },
    tituloOrden:{
        fontSize: 18,
        marginTop: 10,
        textAlign: 'center',
        // borderTopLeftRadius: 15,
        // borderTopRightRadius: 15,
        backgroundColor: '#b51f22',
        padding: 8,
        color: '#ffffff',
        fontWeight: 'bold',
    },
    itemsLista: {
        flexDirection: 'row' , 
        justifyContent: 'space-between',
        borderBottomColor: '#b51f22',
        borderBottomWidth: 1,
        paddingVertical: 8,
        paddingHorizontal: 15,
        backgroundColor: '#ffffff',
    },
    cardBlanca: {
        backgroundColor: '#ffffff',
        paddingHorizontal: 15,
        paddingVertical: 8,
    }
});

let userId = null;
    
  async function authFn() {
    userId = await AsyncStorage.getItem('authToken');
  }

  authFn()

const DATA = [];


export default function Compras({navigation}){
        
        const [orden , SetOrden] = React.useState('');
        const [vista , SetVista] = React.useState('lista');
        const [loaded , SetLoaded] = React.useState(0);

        function makeId(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
              result += characters.charAt(Math.floor(Math.random() * charactersLength));
              counter += 1;
            }
            return result;
        }
        
        
        function verOrden(item)
        {
            SetOrden(item)
            SetLoaded(0)
            SetVista('ver')
            
        }

        function resetForm()
        {
            SetOrden('')
            SetVista('lista')
            SetLoaded(0)
        }

        async function cambiarEstatus(orden , estatus)
        {
            await updateDoc(doc(Firebase, "ordenes", orden.id),
                {estatusCliente: estatus},
            ).then(() => resetForm());
        }

        function estatusPublico(estatus)
        {
            switch (estatus) {
                case 1:
                    return 'Pendiente';
                    break;
                
                case 2:
                    return 'Procesando Orden';
                    break;
                case 3:
                    return 'Enviado / Consignado';
                    break;
                case 4:
                    return 'Entregado';
                    break;
                
                case 0:
                    return 'Rechazado';
                    break;
            
                default:
                    return 'No Definido';
                    break;
            }
        }
        
        function estatusCliente(estatus)
        {
            switch (estatus) {
                case 0:
                    return 'Cancelado';
                    break;
                
                case 1:
                    return 'Recibido Excelente';
                    break;
                case 2:
                    return 'Recibido en buenas condiciones';
                    break;
                case 3:
                    return 'Recibido en malas condiciones';
                    break;
                case 4:
                    return 'No recibido';
                    break;
            
                default:
                    return 'En espera';
                    break;
            }
        }
        
        function estatusPublicoColor(estatus)
        {
            switch (estatus) {
                case 1:
                    return '#000000';
                    break;
                case 2:
                    return '#ff8000';
                    break;
                
                case 3:
                    return '#ffff00';
                    break;
                
                case 4:
                    return '#008f39';
                    break;
                
                case 0:
                    return '#ff0000';
                    break;
            
                default:
                    return '#000000';
                    break;
            }
        }
        
        async function getCompras()
        {
            const q = query(collection(Firebase, "ordenes") , where('userId' , '==' , userId) , orderBy('fechaCreado' , 'desc'));

            const querySnapshot = await getDocs(q);
            
            DATA.length = 0;
            
            querySnapshot.forEach((doc) => {
                  DATA.push({
                    id: doc.id, ...doc.data(),
                  });
                // //console.log(doc.id, " => ", doc.data());
            });
        }   
        
        authFn().then(() => {
            getCompras().then(() => {
                SetLoaded(1);
            });
        })
        
        
    return (
        <View style={{flex: 1}}>
            <ImageBackground source={require('../img/fondo.jpg')} resizeMode="cover" style={styles.imageFondo}>
                <ScrollView style={styles.containerSafeArea}>
                    <View style={styles.container} >
                        <Text style={styles.titulo}>Compras</Text>
                        {vista == 'lista' && <>
                            {loaded ? <>
                                <FlatList
                                    data={DATA}
                                    renderItem={({item}) => 
                                        <View>
                                            <TouchableOpacity style={styles.botonLista} onPress={() => verOrden(item)}>
                                                <Text style={styles.botonListaTexto}>{item.nombre}</Text>
                                                <Text style={styles.botonListaTexto}>${item.total} 
                                                    {item.estatus == 0 && <>
                                                        <Ionicons name="close-circle" size={20} color="#ff0000"></Ionicons>
                                                    </>}
                                                    {item.estatus == 1 && <>
                                                        <Ionicons name="information-circle" size={20} color="#ffff00"></Ionicons>
                                                    </>}
                                                    {item.estatus == 4 && <>
                                                        <Ionicons name="checkmark-circle" size={20} color="#008f39"></Ionicons>
                                                    </>}
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                    }
                                    keyExtractor={item => item.id}
                                    contentContainerStyle={styles.gridLista}
                                    
                                    />

                                    {DATA.length == 0 ? <>
                                        <Ionicons style={{color:'#b51f22' , textAlign: 'center' , marginTop: 100}} name="pricetags" size={100} color="#b51f22"></Ionicons>
                                        <Text style={styles.titulo}>Aún no tienes compras registradas</Text>
                                    </> : <></>}
                            </> : <>
                                <Text>Cargando Compras...</Text>
                            </>}
                        </>}

                        {vista == 'ver' && <>
                                        
                            <TouchableOpacity style={styles.buttonPequeno} onPress={() => {
                                resetForm()
                            }}>
                                <Text style={styles.buttonPequenoTexto} ><Ionicons name="arrow-back" size={20} color="#b51f22"></Ionicons> Volver al listado</Text>
                            </TouchableOpacity>   

                            <Image
                            style={styles.logo}
                            source={ require('../img/logo.png')}
                            />
                            
                            <Text style={styles.tituloOrden}>Orden de compra</Text>
                            <View style={styles.cardBlanca}>
                                <Text>Nombre: {orden.nombre}</Text>
                                <Text>Email: {orden.email}</Text>
                                <Text>Teléfono: {orden.telefono}</Text>
                                <Text>Detalles: {orden.detalles}</Text>
                                <Text style={{ fontWeight: 'bold' }}>Estatus Cliente: { estatusCliente(orden.estatusCliente)}</Text>
                                <Text style={{ color: estatusPublicoColor(orden.estatus) }}>Estatus: { estatusPublico(orden.estatus)}</Text>
                            </View>
                            {orden.tiendaData ? <>
                                <Text style={styles.tituloOrden}>Datos del vendedor</Text>
                                <View style={styles.cardBlanca}>
                                    <Text>Nombre: {orden.tiendaData.name}</Text>
                                    <Text>Dirección: {orden.tiendaData.direccion}</Text>
                                    <Text>Email: {orden.tiendaData.email}</Text>
                                    <Text>Teléfono: {orden.tiendaData.telefono}</Text>

                                    { orden.tipoPago == 2 ? <>
                                        <Text>Datos para Pagos:</Text>
                                        <Text>{orden.tiendaData.datosPago}</Text>
                                    </> : <></>}

                                    { orden.tiendaData.whatsapp ? <>
                                        <TouchableOpacity style={{ backgroundColor: '#25D366' , padding: 10 , marginBottom: 10 , marginTop: 10  }} onPress={() => enviarWhatsapp(orden.TiendaData.whatsapp)}>
                                        <Text style={{ color: '#ffffff' , fontSize: 19 , marginBottom: 10 , textAlign: 'center'  }}><Ionicons name="logo-whatsapp" size={20} color="#ffffff"/> Contactar por WhatsApp</Text>
                                        </TouchableOpacity>
                                    </> : <></>}

                                </View>
                            
                            </> : <></>}
                            

                            { orden.productos && orden.productos.length && <>
                                <Text style={styles.tituloOrden}>Items</Text>
                                <View style={styles.itemsLista}>
                                    <Text style={{width: '60%'}}>Nombre</Text>
                                    <Text>Precio</Text>
                                    <Text>Qty</Text>
                                    <Text>Total</Text>
                                </View>
                            </> }
                            
                            <FlatList
                            data={orden.productos}
                            renderItem={({item}) => 
                                <View style={styles.itemsLista}>     
                                    <Text style={{width: '60%'}}>{item.nombre}</Text>
                                    <Text>{item.precio}</Text>
                                    <Text>{item.cantidad}</Text>
                                    <Text>{item.precio * item.cantidad}</Text>
                                </View>
                            }
                            keyExtractor={item => makeId(10)}
                            contentContainerStyle={styles.gridLista}
                            
                            />

                            { orden.productos && orden.productos.length && <>
                                <View style={styles.itemsLista}>
                                    <Text style={{width: '60%'}}></Text>
                                    <Text></Text>
                                    <Text style={{fontWeight : 'bold'}}>Total:</Text>
                                    <Text style={{fontWeight : 'bold'}}>${orden.total}</Text>
                                </View>
                            </> }
                            
                            <View style={{marginTop : 15}}>
                                { orden.estatus == 1 && <>
                                    <TouchableOpacity style={{marginBottom : 15, width:'100%' , padding: 10 , backgroundColor: '#ff0000'}} onPress={() => cambiarEstatus(orden , 0)}>
                                        <Text style={{ textAlign : 'center' ,color: '#ffffff' , }}>Cancelar Orden 
                                        {orden.estatusCliente == 0 && <>
                                            <Ionicons name="checkmark-circle" size={20} color="#ffffff" style={{marginLeft: 20}} />
                                        </>}
                                        </Text>
                                    </TouchableOpacity>
                                 </>}
                                    {/* <TouchableOpacity style={{marginBottom : 15, width:'100%' , padding: 10 , backgroundColor: '#008f39'}} onPress={() => cambiarEstatus(orden , 1)}>
                                        <Text style={{ textAlign : 'center' ,color: '#ffffff' , }}>Recibido 
                                        {orden.estatusCliente == 1 && <>
                                            <Ionicons name="checkmark-circle" size={20} color="#ffffff" style={{marginLeft: 20}} />
                                        </>}
                                        </Text>
                                    </TouchableOpacity> */}
                                </View>

                            { orden.estatus == 4 && orden.comentado != 1 && <>
                                    <TouchableOpacity style={{ backgroundColor: '#ffff00' , marginBottom : 15, width:'100%' , padding: 10  }} onPress={() => navigation.navigate('Comentar' , {
                                            userId : orden.userId,
                                            tiendaId : orden.tiendaId,
                                            userTiendaId : orden.userTiendaId,
                                            ordenId: orden.id,
                                            nombre: orden.nombre,
                                        })}>
                                        <Text style={{ color: '#000000' , textAlign: 'center' }}><Ionicons name="star" size={20} color="#000000" style={{marginRight: 10}} /> Finalizar Compra</Text>
                                    </TouchableOpacity>
                            </> }
                        </>}
                    </View>            
                </ScrollView>

                <Botones navigation={navigation}/>
            </ImageBackground>
        </View>
    );
}