import React from "react";
import { View , TouchableOpacity , Text , StyleSheet } from "react-native";
import Ionicons from '@expo/vector-icons/Ionicons';
import { useRoute } from '@react-navigation/native';

const styles = StyleSheet.create({
    fondo:{
        // backgroundColor: '#ffffff',
    },
    container : {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',     
        flexDirection: 'row',
        justifyContent: 'space-around', // space-between
        backgroundColor: '#ffffff',
        // borderTopLeftRadius: 30,
        // borderTopRightRadius: 30,
        shadowColor: '#171717',
        shadowOffset: {width: 0, height: -5},
        shadowOpacity: 0.2,
        shadowRadius: 50,
    },
    buttonFix: {
        textAlign: 'center',
        justifyContent: 'center',
        padding: 10,
    },
    buttonFixActivo: {
        textAlign: 'center',
        justifyContent: 'center',
        padding: 10,
        // backgroundColor: '#ffffff',
        // // borderRadius: '50%',
        borderTopStartRadius: 20,
        borderTopEndRadius: 20,
    },
    iconItem: {
        marginLeft : 'auto',
        marginRight : 'auto',
        color: '#808080',
    },
    iconItemActivo: {
        marginLeft : 'auto',
        marginRight : 'auto',
        color: '#b51f22',
    },
    iconTexto: {
        color: '#808080',
    },
    iconTextoActivo: {
        color: '#b51f22',
    },
});

export default function Botones({navigation }){
   
    const route = useRoute();
    const routeName = route.name;
    
    
    return (
        <View style={styles.fondo} >
          <View style={styles.container} >
              <TouchableOpacity style={ routeName == 'ProductosListaAuth' ? styles.buttonFixActivo : styles.buttonFix} onPress={() => navigation.navigate('ProductosListaAuth')}>
                <Ionicons style={routeName == 'ProductosListaAuth' ? styles.iconItemActivo : styles.iconItem} name="home" size={20} color="#b51f22"></Ionicons>
                <Text style={routeName == 'ProductosListaAuth' ? styles.iconTextoActivo : styles.iconTexto}>Inicio</Text>
              </TouchableOpacity>
              
              <TouchableOpacity style={ routeName == 'Favoritos' ? styles.buttonFixActivo : styles.buttonFix} onPress={() => navigation.navigate('Favoritos')}>
                <Ionicons style={routeName == 'Favoritos' ? styles.iconItemActivo : styles.iconItem} name="heart" size={20} color="#b51f22"></Ionicons>
                <Text style={routeName == 'Favoritos' ? styles.iconTextoActivo : styles.iconTexto}>Favoritos</Text>
              </TouchableOpacity>

              <TouchableOpacity style={routeName == 'Carrito' ? styles.buttonFixActivo : styles.buttonFix} onPress={() => navigation.navigate('Carrito')}>
                <Ionicons style={routeName == 'Carrito' ? styles.iconItemActivo : styles.iconItem} name="cart" size={20} color="#b51f22"></Ionicons>
                <Text style={routeName == 'Carrito' ? styles.iconTextoActivo : styles.iconTexto}>Carrito</Text>
              </TouchableOpacity>

              <TouchableOpacity style={routeName == 'Compras' ? styles.buttonFixActivo : styles.buttonFix} onPress={() => navigation.navigate('Compras')}>
                <Ionicons style={routeName == 'Compras' ? styles.iconItemActivo : styles.iconItem} name="pricetags" size={20} color="#b51f22"></Ionicons>
                <Text style={routeName == 'Compras' ? styles.iconTextoActivo : styles.iconTexto}>Compras</Text>
              </TouchableOpacity> 

              <TouchableOpacity style={routeName == 'Perfil' ? styles.buttonFixActivo : styles.buttonFix} onPress={() => navigation.navigate('Perfil')}>
                <Ionicons style={routeName == 'Perfil' ? styles.iconItemActivo : styles.iconItem} name="person" size={20} color="#b51f22"></Ionicons>
                <Text style={routeName == 'Perfil' ? styles.iconTextoActivo : styles.iconTexto}>Perfil</Text>
              </TouchableOpacity>
          </View>
        </View>
    );
}